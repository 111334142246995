import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import { AppService } from './app.service';
import { of, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RestoreState } from '../state/actions/state.action';
import { Store } from '@ngrx/store';
import { StudioState } from '../state/intial-state';
import { AuthGuardService } from './auth-guard.service';
import { BaseUrlService } from './base-url.service';
import { environment } from '../../environments/environment';
import { CommonUtils } from '../utils/common.utils';
import { omit } from 'lodash-es';
import { AppContextService } from '../app.context.service';

@Injectable()
export class StateGuardService implements CanActivate {
  private existingQueryParams: any;
  private response: any;
  private stateId: string;

  constructor(
    private _appService: AppService,
    private _store: Store<StudioState>,
    private _authGuardService: AuthGuardService,
    private _router: Router,
    private _bus: BaseUrlService,
    private _appContext: AppContextService
  ) {}

  canActivate(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const stateId = childRoute.queryParams['stateId'];
    if (stateId) {
      this.existingQueryParams = JSON.parse(
        JSON.stringify(childRoute.queryParams)
      );
      delete this.existingQueryParams.stateId;
      document.body.classList.add('state-loading');
      return this._appService.getStateDetails(stateId).pipe(
        map((res) => {
          try {
            this.stateId = stateId;
            this.response = CommonUtils.decryptData(res.content);
            if (!this.response.route.includes(this._bus.baseUri)) {
              return true;
            }
            if (this.response.state.additionalData.isForPayment) {
              this.handleStateRestore();
              document.body.classList.remove('state-loading');
              return true;
            } else {
              this._router.navigate([
                `${this._bus.baseUri}/${environment.uri.retrieveQuote}`,
              ]);
              document.body.classList.remove('state-loading');
              return true;
            }
          } catch (e) {
            this._router.navigate([
              `${this._bus.baseUri}/${environment.uri.error}`,
            ]);
            return true;
          }
        }),
        catchError(() => {
          if (this._appContext.get('pages.retrieveQuote')) {
            this._router.navigate(
              [`${this._bus.baseUri}/${environment.uri.retrieveQuote}`],
              { queryParams: { status: 'expired' } }
            );
          } else {
            this._router.navigate([`${this._bus.baseUri}`]);
          }
          document.body.classList.remove('state-loading');
          return of(true);
        })
      );
    } else {
      document.body.classList.remove('state-loading');
      return of(true);
    }
  }

  handleStateRestore() {
    this._authGuardService.isAuthenticated(this.response.state.authGuard);
    this._store.dispatch(
      new RestoreState(
        omit(this.response.state, ['additionalData.isForPayment'])
      )
    );
    this._router.navigate([this.response.route], {
      queryParams: this.existingQueryParams,
      queryParamsHandling: 'merge',
    });
  }

  saveQuoteDetails() {
    return {
      stateId: this.stateId,
      saveQuoteDetails: this.response.state.saveQuote,
    };
  }
}
