// Packages
import {
  HttpClient,
  HTTP_INTERCEPTORS,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// Effects
import { ApplicantDetailsEffects } from './state/effects/applicant-details-effects';
import { DeviceDetailsEffects } from './state/effects/device-details-effects';
import { LanguageEffects } from './state/effects/language-effects';
import { LoadingIndicatorEffects } from './state/effects/loading-indicator.effects';
import { PlanEffects } from './state/effects/plan-effects';
import { PurchaseEffects } from './state/effects/purchase-effects';
import { PartnerEffects } from './state/effects/partner-effects';
import { ScreenerDetailsEffects } from './state/effects/screener-details-effects';
import { TenantEffects } from './state/effects/tenant-effects';

// Guards
import { AppContextGuard, RouteGuard } from './guards';
import { MaintenanceGuard } from './services/maintenance-guard.service';
import { QueryParamsGuard } from './guards/query-params.guard';
import { StateGuardService } from './services/state.guard.service';
import { PlanGuard } from './guards/plan-guard';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { AppNavbarModule, ComponentsModule } from './components';
import { ToastModule } from './components/shared/toast/toast.module';
import { DialogModule } from '../../projects/studio-widgets/src/lib/shared/dialog/dialog.module';
import { PdfViewerDialogModule } from '../../projects/studio-widgets/src/lib/shared/pdf-viewer-dialog/pdf-viewer-dialog.module';
import { LanguageSelectorModule } from './components/shared/language-selector/language-selector.module';
import { LoadingModule } from './components/shared/loading/loading.module';
import { ScrollToTopModule } from './components/shared/scroll-to-top/scroll-to-top.module';
import { MatDialogRef } from '@angular/material/dialog';

// Required for Touch functionality of CRUX Components
import 'hammerjs';

// Reducers
import { appContextReducer } from './state/reducers/app-context.reducer';
import { applicantDetailsReducer } from './state/reducers/applicant-details.reducer';
import { authGuardReducer } from './state/reducers/auth-guard.reducer';
import { additionalDataReducer } from './state/reducers/additional-data.reducer';
import { bottomCtaReducer } from './state/reducers/bottom-cta.reducer';
import { contactUsDetailsReducer } from './state/reducers/contact-us-details.reducer';
import { deviceDetailsReducer } from './state/reducers/device-details.reducer';
import { languageConfigReducer } from './state/reducers/language.reducer';
import { leadGenDetailsReducer } from './state/reducers/lead-gen-details.reducer';
import {
  loadingIndicatorReducer,
  sectionsReadyReducer,
} from './state/reducers';
import { otpReducer } from './state/reducers/otp.reducer';
import { partnerConfigReducer } from './state/reducers/partner.reducer';
import { planReducer } from './state/reducers/plan.reducer';
import { purchaseReducer } from './state/reducers/purchase.reducer';
import { prefetchDataReducer } from './state/reducers/prefetch-data.reducer';
import { quoteDetailsReducer } from './state/reducers/quote-details.reducer';
import { saveQuoteReducer } from './state/reducers/save-quote.reducer';
import { screenerDetailsReducer } from './state/reducers/screener-details.reducer';
import { ssiQuoteEsimateReducer } from './state/reducers/ssi-quote-estimate.reducer';
import { stateReducer } from './state/reducers/state.reducer';
import { tenantReducer } from './state/reducers/tenant.reducer';

// Services
import { AppContextService } from './app.context.service';
import { AppService } from './services/app.service';
import { AuthGuardService } from './services/auth-guard.service';
import { BaseUrlService } from './services/base-url.service';
import { ExpiryGuardService } from './services/expiry-guard.service';
import { LoadingService } from './components/shared/loading/loading.service';
import { OtpGuardService } from './services/otp-guard.service';
import { PageGuardService } from './services/page-guard.service';
import { PhoneSelectGuardService } from './services/phone-select-guard.service';
import { WINDOW_PROVIDERS } from './services/window.service';
import { FontService } from './services/font.service';

// Others
import { AppComponent } from './app.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { environment } from '../environments/environment';
import { HttpHeaderInterceptor } from './interceptors';
import { initialState } from './app.state';
import { LayoutModule } from './layout';
import { STUDIO } from './const/studio-base-href';
import { StateActions } from './state/actions/state.action';
import { assign, cloneDeep } from 'lodash';
import { cruxHttpClient, CruxHttpClientService } from '@crux/services/http';
import {
  AnalyticsModule,
  AnalyticsService,
  ApplicationInsightsService,
  GoogleTagManagerService,
} from '@crux/services/analytics';
import { PipesModule } from '@crux/components/pipes';
import { CustomTimingService } from '@crux/services/util';

export const httpProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpHeaderInterceptor,
    multi: true,
  },
  {
    provide: CruxHttpClientService,
    useFactory: cruxHttpClient,
    deps: [HttpClient],
  },
];

export const ngrxImports = [
  StoreModule.forRoot(
    {
      additionalData: additionalDataReducer,
      appContext: appContextReducer,
      applicantDetails: applicantDetailsReducer,
      authGuard: authGuardReducer,
      contactUsDetails: contactUsDetailsReducer,
      bottomCta: bottomCtaReducer,
      deviceDetails: deviceDetailsReducer,
      sectionsReady: sectionsReadyReducer,
      language: languageConfigReducer,
      leadGenDetails: leadGenDetailsReducer,
      loadingIndicators: loadingIndicatorReducer,
      otp: otpReducer,
      partner: partnerConfigReducer,
      plan: planReducer,
      prefetchData: prefetchDataReducer,
      purchase: purchaseReducer,
      quoteDetails: quoteDetailsReducer,
      saveQuote: saveQuoteReducer,
      ssiQuoteEstimate: ssiQuoteEsimateReducer,
      screenerDetails: screenerDetailsReducer,
      state: stateReducer,
      tenant: tenantReducer,
    },
    { initialState, metaReducers: [restoreState] }
  ),
  EffectsModule.forRoot([
    ApplicantDetailsEffects,
    LoadingIndicatorEffects,
    PurchaseEffects,
    PartnerEffects,
    LanguageEffects,
    PlanEffects,
    DeviceDetailsEffects,
    ScreenerDetailsEffects,
    TenantEffects,
  ]),
  environment.production
    ? []
    : StoreDevtoolsModule.instrument({
        maxAge: 25,
      }),
];
export function getModuleRegisrty(moduleName: any, modulePath: string) {
  const path = modulePath.split('.')[0];
  return () => import(`../../${path}.module`);
}

export function appContextFactory(appContextService: AppContextService) {
  return async () => {
    return appContextService.load();
  };
}

export function restoreState(reducer) {
  return function (state, action) {
    if (action.type === StateActions.RESTORE_STATE) {
      state = assign(cloneDeep(action).payload, { state: { restored: true } });
    }
    return reducer(state, action);
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Analytics Module for instrumenting events
    AnalyticsModule.forRoot(
      [GoogleTagManagerService, ApplicationInsightsService],
      {
        applicationInsights: {
          instrumentationKey: environment.appInsightsInstrumentationKey,
        },
      }
    ),
    ngrxImports,
    AppNavbarModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    ComponentsModule,
    DialogModule,
    DeviceDetectorModule.forRoot(),
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    JwtModule,
    LayoutModule,
    LoadingModule,
    LanguageSelectorModule,
    PdfViewerDialogModule,
    ReactiveFormsModule,
    PipesModule,
    ScrollToTopModule,
    ToastModule,
  ],
  providers: [
    { provide: MatDialogRef, useValue: { close: {} } },
    WINDOW_PROVIDERS,
    httpProviders,
    AppContextGuard,
    AppContextService,
    AppService,
    AuthGuardService,
    BaseUrlService,
    CustomTimingService,
    ExpiryGuardService,
    JwtHelperService,
    LoadingService,
    MaintenanceGuard,
    OtpGuardService,
    PageGuardService,
    PhoneSelectGuardService,
    QueryParamsGuard,
    PlanGuard,
    RouteGuard,
    StateGuardService,
    STUDIO,
    FontService,
    AnalyticsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
