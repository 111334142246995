import { Action } from '@ngrx/store';

export enum AppContextActions {
  LOAD_APP_CONTEXT = '[APP_CONTEXT] LOAD APP CONTEXT',
  LOAD_APP_CONTEXT_SUCCESS = '[APP_CONTEXT] LOAD APP CONTEXT SUCCESS',
  LOAD_APP_CONTEXT_FAILURE = '[APP_CONTEXT] LOAD APP CONTEXT FAILURE',
  ADD_APP_CONTEXT = '[APP_CONTEXT] ADD APP CONTEXT TO LS',
}

export class LoadAppContextAction implements Action {
  readonly type: string = AppContextActions.LOAD_APP_CONTEXT;
}

export class LoadAppContextSuccessAction implements Action {
  readonly type: string = AppContextActions.LOAD_APP_CONTEXT_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadAppContextFailureAction implements Action {
  readonly type: string = AppContextActions.LOAD_APP_CONTEXT_FAILURE;

  constructor() {}
}

export class AddAppContextAction implements Action {
  readonly type: string = AppContextActions.ADD_APP_CONTEXT;

  constructor(public payload: any) {}
}

export type AppContextActionsType =
  | LoadAppContextAction
  | LoadAppContextSuccessAction
  | LoadAppContextFailureAction
  | AddAppContextAction;
