import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'studio-language-selector.',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LanguageSelectorComponent implements OnInit {
  header: string;
  languages: any[];
  enterLabel: string;
  selectedLanguage: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.header = this.data.popup.header;
    this.languages = this.data.languages;
    this.enterLabel = this.data.popup.enterLabel;
    this.selectedLanguage = this.languages.filter((x) => x.default)[0].value;
  }
}
