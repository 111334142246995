import { Action } from '@ngrx/store';

export enum OtpActions {
  OTP_ADD_OTP_DATA = '[OTP] ADD OTP DATA',
}

export class AddOtpData implements Action {
  readonly type: string = OtpActions.OTP_ADD_OTP_DATA;

  constructor(public payload: { [key: string]: any }) {}
}

export type OtpActionTypes = AddOtpData;
