import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { StudioState } from '../intial-state';
import { ScreenerDetailsActions } from '../actions/screener-details.action';

@Injectable()
export class ScreenerDetailsEffects {
  @Effect()
  navigateToStep$ = this._actions$.pipe(
    ofType(ScreenerDetailsActions.ADD_SCREENER_DETAILS),
    withLatestFrom(this.store$),
    map(([, storeState]) => {
      sessionStorage.setItem(
        'screenerDetails',
        JSON.stringify(storeState.screenerDetails)
      );
      return {
        type: ScreenerDetailsActions.ADD_SCREENER_DETAILS_TO_SESSION_STORAGE,
        payload: false,
      };
    })
  );

  constructor(private _actions$: Actions, private store$: Store<StudioState>) {}
}
