import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { StudioState } from '../state/intial-state';
import { AppContextService } from '../app.context.service';
import { BaseUrlService } from '../services/base-url.service';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import _camelCase from 'lodash-es/camelCase';
import _isEmpty from 'lodash-es/isEmpty';

@Injectable()
export class PageResolver implements CanActivate {
  constructor(
    private router: Router,
    private _bus: BaseUrlService,
    private _store: Store<StudioState>,
    private route: ActivatedRoute,
    private _appContextService: AppContextService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const pageKey = _camelCase(route.url[0].path);

    if (pageKey === this._appContextService.get(`pages.${pageKey}.route`)) {
      const selfUrls$ = [
        ...this._appContextService.getSelfUrls(
          this._appContextService.get(`pages.${pageKey}`),
          `pages.${pageKey}`
        ),
      ];
      if (_isEmpty(selfUrls$)) {
        return of(true);
      }

      return forkJoin(selfUrls$).pipe(
        map(() => {
          return true;
        })
      );
    }

    return of(true);
  }
}
