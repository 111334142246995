import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';
import { LoadingService } from '../../components/shared/loading/loading.service';
import {
  LoadingIndicatorActions,
  LoadingIndicatorHideAction,
  LoadingIndicatorShowAction,
} from '../actions';
import { Store } from '@ngrx/store';
import { StudioState } from '../intial-state';

@Injectable()
export class LoadingIndicatorEffects {
  @Effect()
  showLoadingIndicator$ = this._actions$.pipe(
    ofType(LoadingIndicatorActions.LOADING_INDICATOR_SHOW),
    withLatestFrom(this._store),
    map(([action, storeState]) => {
      const typedAction = <LoadingIndicatorShowAction>action;

      if (
        storeState.loadingIndicators &&
        storeState.loadingIndicators.some(
          (li: string) => li === typedAction.payload.key
        )
      ) {
        return {
          type: LoadingIndicatorActions.LOADING_INDICATOR_SHOW_SUCCESS,
          payload: typedAction.payload.key,
        };
      }

      typedAction.payload.elementRef
        ? this._loadingService.openForContainer(
            typedAction.payload.loading.title,
            typedAction.payload.loading.message
          )
        : this._loadingService.open(
            typedAction.payload.loading.title,
            typedAction.payload.loading.message
          );

      return {
        type: LoadingIndicatorActions.LOADING_INDICATOR_SHOW_SUCCESS,
        payload: typedAction.payload.key,
      };
    })
  );

  @Effect()
  hideLoadingIndicator$ = this._actions$.pipe(
    ofType('[LOADING INDICATOR] LOADING INDICATOR HIDE'),
    withLatestFrom(this._store),
    map(([action, storeState]) => {
      const loadingIndicatorKey = <string[]>storeState.loadingIndicators
        ? (<string[]>storeState.loadingIndicators).find(
            (key: string) =>
              key === (<LoadingIndicatorHideAction>action).payload
          )
        : null;

      this._loadingService.close();

      return {
        type: LoadingIndicatorActions.LOADING_INDICATOR_HIDE_SUCCESS,
        payload: (<LoadingIndicatorHideAction>action).payload,
      };
    })
  );

  constructor(
    private _actions$: Actions,
    private _loadingService: LoadingService,
    private _store: Store<StudioState>
  ) {}
}
