import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'studio-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss'],
})
export class SocialMediaComponent {
  @Input()
  socialLinks: {
    class: string;
    url: string;
  }[];

  constructor() {}
}
