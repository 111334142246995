import { Component, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { NavbarService } from '@crux/components/navbar';
import { AppContextService } from '../../app.context.service';
import { STUDIO } from '../../const/studio-base-href';
import { AppService } from '../../services/app.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'studio-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements AfterViewInit {
  private _baseHref = this._studio.base_href;
  private _showLastNavItem = false;
  private _logoHeight;
  private _logoWidth;

  constructor(
    private _navbarService: NavbarService,
    private _appService: AppService,
    private _appContext: AppContextService,
    private _studio: STUDIO
  ) {
    const {
      items,
      mobileItem,
      logoAltText,
      showLastNavItem,
    } = this._appContext.get(`common.navbar`);

    this._showLastNavItem = showLastNavItem;
    const { logo, logoHeight, logoWidth } = this._appContext.get(
      `common.branding`
    );
    this._logoHeight = logoHeight;
    this._logoWidth = logoWidth;

    this._navbarService.emptyNavItems();
    this._navbarService.updateLogo({
      src: this._appService.isChubbBranded()
        ? 'assets/logos/chubb.svg'
        : !this._appService.isAgent()
        ? `/assets/logos/underwritten_by_chubb_${this._studio.base_href
            .split('/')
            .pop()}.svg`
        : logo,
      alt: logoAltText,
      routeOrFunction: this._baseHref,
      navigationType: 'route',
    });

    for (const navItem of items) {
      if (!navItem.type) {
        navItem.type = 'route';
        navItem.routeOrFunction =
          this._baseHref + '/' + navItem.routeOrFunction;
      }
      navItem.iconPath = this._appService.isChubbBranded()
        ? navItem.chubbIconPath
        : navItem.iconPath;
      _navbarService.addRightNavItem(navItem);
    }

    const mobileNavItems = [];
    for (const mobileNavItem of mobileItem) {
      if (!mobileNavItem.type) {
        mobileNavItem.type = 'route';
        mobileNavItem.routeOrFunction =
          this._baseHref + '/' + mobileNavItem.routeOrFunction;
      }
      mobileNavItem.iconPath = this._appService.isChubbBranded()
        ? mobileNavItem.chubbIconPath
        : mobileNavItem.iconPath;
      mobileNavItems.push(mobileNavItem);
    }
    _navbarService.setMobileItems(mobileNavItems);
  }

  ngAfterViewInit(): void {
    if (this._showLastNavItem) {
      document.getElementById('navbar-header').classList.add('showLastNavItem');
    }
    if (this._logoHeight) {
      const logoElement = document.getElementsByClassName(
        'crux-navbar__logo-div--sales'
      )[0] as HTMLElement;
      logoElement.style.height = this._logoHeight;
    }
    setTimeout(() => {
      if (this._logoHeight && this._logoWidth) {
        const logoElement = document.getElementsByClassName(
          'crux-navbar__logo-div--sales'
        )[0] as HTMLElement;
        logoElement.style.height = this._logoHeight;
        logoElement.style.width = this._logoWidth;
      }
    });
  }
}
