import {
  ContactUsDetailsActionTypes,
  ContactUsDetailsActions,
} from '../actions/contact-us-details.action';

export function contactUsDetailsReducer(
  state = {},
  action: ContactUsDetailsActionTypes
) {
  switch (action.type) {
    case ContactUsDetailsActions.ADD_CONTACT_US_DETAILS:
      return { ...state, ...action.payload };
    case ContactUsDetailsActions.CLEAR_CONTACT_US_DETAILS:
      return {};
    default:
      return state;
  }
}
