import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppContextService } from '../app.context.service';
import { StudioState } from '../state/intial-state';
import { AddPrefetchData } from '../state/actions/prefetch-data.action';
import { BaseUrlService } from '../services/base-url.service';
import { environment } from '../../environments/environment';
import { AuthGuardService } from '../services/auth-guard.service';

@Injectable()
export class PrefetchDataResolver implements Resolve<any> {
  constructor(
    private _httpClient: HttpClient,
    private _appContext: AppContextService,
    private _store: Store<StudioState>,
    private _bus: BaseUrlService,
    private _router: Router,
    private _authGuardService: AuthGuardService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    let storeData: any;
    const prefetchData = this._appContext.get(route.data['appContextPath']);
    const promises = [];

    if (prefetchData) {
      this._store.pipe(take(1)).subscribe((store: any) => {
        storeData = store['prefetchData'];
      });

      const requestOptions = {
        headers: new HttpHeaders({
          'X-Studio-Referer': location.origin + this._bus.baseUri,
        }),
      };

      Object.keys(prefetchData).forEach((key) => {
        promises.push(
          this._httpClient
            .get(prefetchData[key], requestOptions)
            .toPromise()
            .catch((error) => {
              console.error(error);
              this._router.navigate([
                `${this._bus.baseUri}/${environment.uri.error}`,
              ]);
            })
            .then((res) => {
              this._store.dispatch(
                new AddPrefetchData({
                  [key]: res,
                })
              );
            })
        );
      });
    }
    return Promise.all(promises);
  }
}
