import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService implements OnDestroy {
  constructor(private snackBar: MatSnackBar) {}

  private _snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  private _snackBarRefSubscription: Subscription;

  private _defaults: MatSnackBarConfig = {
    panelClass: 'studio-toast',
    verticalPosition: 'top',
    duration: 5000,
  };

  show(
    message: string,
    action?: string,
    config?: MatSnackBarConfig,
    callBack?: () => void
  ): void {
    this._snackBarRef = this.snackBar.open(message, action, {
      ...this._defaults,
      ...config,
    });

    this._snackBarRefSubscription = this._snackBarRef
      .onAction()
      .subscribe((data) => {
        if (callBack) {
          callBack();
        }
      });
  }

  showSuccess(
    message: string,
    action?: string,
    config?: MatSnackBarConfig,
    callBack?: () => void
  ): void {
    this.show(
      message,
      action,
      {
        ...config,
        ...{ panelClass: ['studio-toast', 'studio-toast--success'] },
      },
      callBack
    );
  }

  showWarning(
    message: string,
    action?: string,
    config?: MatSnackBarConfig,
    callBack?: () => void
  ): void {
    this.show(
      message,
      action,
      {
        ...config,
        ...{ panelClass: ['studio-toast', 'studio-toast--warning'] },
      },
      callBack
    );
  }

  showError(
    message: string,
    action?: string,
    config?: MatSnackBarConfig,
    callBack?: () => void
  ): void {
    this.show(
      message,
      action,
      { ...config, ...{ panelClass: ['studio-toast', 'studio-toast--error'] } },
      callBack
    );
  }

  ngOnDestroy(): void {
    this._snackBarRef.dismiss();
    this._snackBarRefSubscription.unsubscribe();
  }
}
