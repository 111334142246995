import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { BaseUrlService } from './base-url.service';
import { AppContextService } from '../app.context.service';
import { environment } from '../../environments/environment';
import { isBefore } from 'date-fns';
import { isEmpty } from 'lodash';

@Injectable()
export class MaintenanceGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _appContextService: AppContextService,
    private _bus: BaseUrlService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this._appContextService.get('maintenance')) {
      const { startDate, endDate } = this._appContextService.get('maintenance');
      const currentDate = new Date();
      const isStartDateOver = isEmpty(startDate)
        ? true
        : isBefore(new Date(startDate), currentDate);
      const isEndDateOver =
        !isEmpty(endDate) && isBefore(new Date(endDate), currentDate);
      if (isStartDateOver && !isEndDateOver) {
        this._router.navigate([`${environment.uri.maintenance}`]);
      }
    }
    return true;
  }
}
