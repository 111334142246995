import { LOCALE_ID } from '@angular/core';
import { AppContextService } from './app.context.service';
import {
  studioLocale,
  studioLocaleId,
} from './factories/studio-locale.factory';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CRUX_LOCALE_DATA } from '@crux/components/pipes';

export const PASSPHRASE = 'studio';

export const POLICY_STATUS_CODE = {
  SUCCESS: '00',
  UNKNOWN_PAYMENT_EXCEPTION: '07',
  DUPLICATE_PAYMENT_EXCEPTION: '08',
  INVALID_PAYMENT_EXCEPTION: '04',
  DUPLICATE_POLICY_HOLDER_EXCEPTION: '03',
  DUPLICATE_PRODUCT_DATA_EXCEPTION: '12',
  PREMIUM_CHECK_FAILED_EXCEPTION: '38',
  SANCTION_CHECK_FAILED_EXCEPTION: '222',
};

export const IMEI_PAGES = {
  START_PAGE: 1,
  OTP_PAGE: 2,
  IMEI_PAGE: 3,
  CONFIRMATION_PAGE: 4,
};

export const RETRIEVE_QUOTE_PAGES = {
  START_PAGE: 1,
  OTP_PAGE: 2,
};

export const IMEI_EXCEPTIONS = {
  URL_EXPIRED: '1A',
  MAX_OTP_REQUEST: '2A',
  POLICY_NOT_FOUND: '2B',
  OTP_SERVICE_ERROR: '2C',
  PAYMENT_PROBLEM: '4A',
  IMEI_DUPLICATE: '4B',
};

export const QUOTE_EXCEPTIONS = {
  URL_EXPIRED: '1A',
  MAX_OTP_REQUEST: '2A',
  QUOTE_NOT_FOUND: '2B',
  OTP_SERVICE_ERROR: '2C',
  PAYMENT_PROBLEM: '4A',
};

export const LOCALE_PROVIDERS = [
  {
    provide: CRUX_LOCALE_DATA,
    deps: [AppContextService],
    useFactory: studioLocale,
  },
  {
    provide: LOCALE_ID,
    deps: [AppContextService],
    useFactory: studioLocaleId,
  },
];

export const DATE_PROVIDERS = [
  { provide: MAT_DATE_LOCALE, useValue: 'en-SG' },
  {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  },
  {
    provide: MAT_DATE_FORMATS,
    useValue: {
      parse: {
        dateInput: 'DD/MM/YYYY',
      },
      display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MM YYYY',
      },
    },
  },
];

/** SME **/
export const WORK_COMP_ID = '1321';

/** OTP **/
export const OTP_ERRORS = {
  ERR_RESEND_OTP_ATTEMPT_EXCEEDED: 'E09',
  ERR_OTP_ATTEMPT_EXCEEDED: 'E08',
  ERR_OTP_CODE_INVALID: 'E01',
  ERR_OTP_EXPIRED: 'E02',
};
