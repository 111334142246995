import { StudioState } from './state/intial-state';
import {
  appContextState,
  sectionsReady,
  loadingIndicatorState,
  partnerInitialState,
  languageInitialState,
  purchaseInitialState,
  tenantInitialState,
  authGuardState,
} from './state/intial-state';

export const initialState: StudioState = {
  appContext: appContextState,
  authGuard: authGuardState,
  sectionsReady: sectionsReady,
  loadingIndicators: loadingIndicatorState,
  partner: partnerInitialState,
  language: languageInitialState,
  purchase: purchaseInitialState,
  plan: {},
  applicantDetails: {},
  deviceDetails: {},
  screenerDetails: {},
  tenant: tenantInitialState,
  saveQuote: {},
  quoteDetails: {},
};
