import {
  SaveQuoteActionTypes,
  SaveQuoteActions,
} from '../actions/save-quote.action';

export function saveQuoteReducer(state = {}, action: SaveQuoteActionTypes) {
  switch (action.type) {
    case SaveQuoteActions.ADD_SAVE_QUOTE_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
