import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { StudioState } from '../state/intial-state';
import { AddQueryParamsAction } from '../state/actions/additional-data.action';
import * as _ from 'lodash';

@Injectable()
export class QueryParamsGuard implements CanActivateChild {
  constructor(private _store: Store<StudioState>) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (childRoute.queryParams && !_.isEmpty(childRoute.queryParams)) {
      this._store.dispatch(new AddQueryParamsAction(childRoute.queryParams));
    }
    return true;
  }
}
