import { Action } from '@ngrx/store';

export enum SsiQuoteEstimateActions {
  ADD_SSI_QUOTE_ESTIMATE_DETAILS = '[SSI QUOTE ESTIMATE] ADD SAVE QUOTE DETAILS',
}

export class AddSsiQuoteEstimateDetails implements Action {
  readonly type: string =
    SsiQuoteEstimateActions.ADD_SSI_QUOTE_ESTIMATE_DETAILS;

  constructor(public payload: { [key: string]: any }) {}
}

export type SsiQuoteEstimateActionTypes = AddSsiQuoteEstimateDetails;
