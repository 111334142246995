import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { DynamicOverlay } from './dynamic-overlay/dynamic-overlay.service';
import { DynamicOverlayContainer } from './dynamic-overlay/dynamic-overlay-container';

@NgModule({
  imports: [CommonModule],
  declarations: [LoadingComponent],
  entryComponents: [LoadingComponent],
  exports: [LoadingComponent],
  providers: [DynamicOverlay, DynamicOverlayContainer],
})
export class LoadingModule {}
