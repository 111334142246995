import { Action } from '@ngrx/store';
import { Loading } from '../../components/shared/loading/loading.model';
import { ElementRef } from '@angular/core';

export enum LoadingIndicatorActions {
  LOADING_INDICATOR_SHOW = '[LOADING INDICATOR] LOADING INDICATOR SHOW',
  LOADING_INDICATOR_SHOW_SUCCESS = '[LOADING INDICATOR] LOADING INDICATOR SHOW SUCCESS',
  LOADING_INDICATOR_HIDE = '[LOADING INDICATOR] LOADING INDICATOR HIDE',
  LOADING_INDICATOR_HIDE_SUCCESS = '[LOADING INDICATOR] LOADING INDICATOR HIDE SUCCESS',
}

export class LoadingIndicatorShowAction implements Action {
  readonly type: string = LoadingIndicatorActions.LOADING_INDICATOR_SHOW;

  constructor(
    public payload: { key: string; loading: Loading; elementRef?: ElementRef }
  ) {}
}

export class LoadingIndicatorShowSuccessAction implements Action {
  readonly type: string =
    LoadingIndicatorActions.LOADING_INDICATOR_SHOW_SUCCESS;

  constructor(public payload: string) {}
}

export class LoadingIndicatorHideAction implements Action {
  readonly type: string = LoadingIndicatorActions.LOADING_INDICATOR_HIDE;

  constructor(public payload: string) {}
}

export class LoadingIndicatorHideSuccessAction implements Action {
  readonly type: string =
    LoadingIndicatorActions.LOADING_INDICATOR_HIDE_SUCCESS;

  constructor(public payload: string) {}
}

export type LoadingIndicatorActionType =
  | LoadingIndicatorShowAction
  | LoadingIndicatorShowSuccessAction
  | LoadingIndicatorHideAction
  | LoadingIndicatorHideSuccessAction;
