import {
  DeviceDetailsActionTypes,
  DeviceDetailsActions,
} from '../actions/device-details.action';

export function deviceDetailsReducer(
  state = {},
  action: DeviceDetailsActionTypes
) {
  switch (action.type) {
    case DeviceDetailsActions.ADD_SELECTED_PHONE_MAKE_AND_MODEL:
      return { ...state, ...action.payload };
    case DeviceDetailsActions.ADD_SELECTED_PHONE_IMEI:
      return { ...state, ...action.payload };
    case DeviceDetailsActions.CLEAR_SELECTED_PHONE_DETAILS:
      return {};
    case DeviceDetailsActions.SHOW_MORE_MODELS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
