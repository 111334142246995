import {
  Component,
  ViewEncapsulation,
  Inject,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WidgetOutputEvent } from '../../../../../../src/app/components/shared/widget/widget-output-event.model';

@Component({
  selector: 'csw-studio-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogComponent implements OnInit {
  @Output() output = new EventEmitter<WidgetOutputEvent>();

  public title: string;
  public description: string;
  public messages: string[];
  public illustration: string;
  public buttonLabel: string;
  public cancelButtonLabel: string;
  public type: string;
  public containerMaxHeight: string;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.title = this.data.title;
    this.description = this.data.description;
    this.messages = this.data.message;
    this.illustration = this.data.illustration;
    this.buttonLabel = this.data.buttonLabel;
    this.cancelButtonLabel = this.data.cancelButtonLabel;
    this.type = this.data.type || 'dialog';
    this.containerMaxHeight =
      screen.width < 600
        ? `calc(100vh - 110px - ${screen.height - window.innerHeight}px)`
        : `calc(100vh - 160px)`;
  }
}
