import {
  LeadGenDetailsActionTypes,
  LeadGenDetailsActions,
} from '../actions/lead-gen-details.action';

export function leadGenDetailsReducer(
  state = {},
  action: LeadGenDetailsActionTypes
) {
  switch (action.type) {
    case LeadGenDetailsActions.ADD_LEAD_GEN_DETAILS:
      return { ...state, ...action.payload };
    case LeadGenDetailsActions.CLEAR_LEAD_GEN_DETAILS:
      return {};
    default:
      return state;
  }
}
