import { BaseUrlService } from './../services/base-url.service';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppContextService } from '../app.context.service';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  constructor(
    private _appContext: AppContextService,
    private _bus: BaseUrlService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authReq = req.clone({
      headers: req.headers.set(
        'X-Studio-Referer',
        location.origin + this._bus.baseUri
      ),
      setHeaders: this._appContext.get('httpHeaders') ? this._appContext.get('httpHeaders').toString() : this._appContext.get('httpHeaders'),

    });
    return next.handle(authReq);
  }
}
