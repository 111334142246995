import {
  SsiQuoteEstimateActions,
  SsiQuoteEstimateActionTypes,
} from '../actions/ssi-quote-estimate.action';

export function ssiQuoteEsimateReducer(
  state = {},
  action: SsiQuoteEstimateActionTypes
) {
  switch (action.type) {
    case SsiQuoteEstimateActions.ADD_SSI_QUOTE_ESTIMATE_DETAILS:
      return { ...action.payload };
    default:
      return state;
  }
}
