import { Action } from '@ngrx/store';

export enum StateActions {
  RESTORE_STATE = '[STATE] RESTORE STATE',
  SET_STATE_ID = '[STATE] SET STATE ID',
}

export class RestoreState implements Action {
  readonly type: string = StateActions.RESTORE_STATE;

  constructor(public payload: any) {}
}

export class SetStateId implements Action {
  readonly type: string = StateActions.SET_STATE_ID;

  constructor(public payload: any) {}
}

export type StateActionTypes = RestoreState | SetStateId;
