import {
  AdditionalDataActionsType,
  AdditionalDataActions,
} from '../actions/additional-data.action';

export function additionalDataReducer(
  state = {},
  action: AdditionalDataActionsType
) {
  switch (action.type) {
    case AdditionalDataActions.ADD_QUERY_PARAMS:
      return { ...state, ...{ queryParams: action.payload } };
    default:
      return state;
  }
}
