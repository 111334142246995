import { Action } from '@ngrx/store';

export enum TenantActions {
  TENANT_LOAD_SUCCESS = '[TENANT] TENANT LOAD SUCCESS',
  TENANT_LOAD_FAILURE = '[TENANT]  TENANT LOAD FAILURE',
  TENANT_SESSION_STORAGE_SUCCESS = '[TENANT]  TENANT SESSION STORAGE SUCCESS',
}

export class TenantLoadSuccess implements Action {
  readonly type: string = TenantActions.TENANT_LOAD_SUCCESS;

  constructor(public payload: { [key: string]: string }) {}
}

export class TenantLoadFailure implements Action {
  readonly type: string = TenantActions.TENANT_LOAD_FAILURE;

  constructor(public payload: { [key: string]: string }) {}
}

export class TenantSessionStorageSuccess implements Action {
  readonly type: string = TenantActions.TENANT_SESSION_STORAGE_SUCCESS;

  constructor(public payload: { [key: string]: any }) {}
}

export type TenantActionTypes =
  | TenantLoadSuccess
  | TenantLoadFailure
  | TenantSessionStorageSuccess;
