import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadModuleDirective } from './load-module.directive';
import { LoadingPlaceholderComponent } from './loading-placeholder/loading-placeholder.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LoadModuleDirective, LoadingPlaceholderComponent],
  entryComponents: [LoadingPlaceholderComponent],
  exports: [LoadModuleDirective, LoadingPlaceholderComponent],
})
export class LoadModuleModule {}
