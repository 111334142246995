import { OtpActions, OtpActionTypes } from '../actions/otp.action';

export function otpReducer(state = {}, action: OtpActionTypes) {
  switch (action.type) {
    case OtpActions.OTP_ADD_OTP_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
