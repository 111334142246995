import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment['antiFlickerId']) {
  const style = document.createElement('style');
  style.innerHTML = '.async-hide .root-container { opacity: 0 !important }';
  document.head.appendChild(style);

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = ` (function (a, s, y, n, c, h, i, d, e) {
        s.className += ' ' + y; h.start = 1 * new Date;
        h.end = i = function () { s.className = s.className.replace(RegExp(' ?' + y), '') };
        (a[n] = a[n] || []).hide = h; setTimeout(function () { i(); h.end = null }, c); h.timeout = c;
        })(window, document.documentElement, 'async-hide', 'dataLayer', 4000,
        { '${environment['antiFlickerId']}': true });
      `;
  document.head.appendChild(script);
}

if (environment['gaTrackingId'] || environment['gtmTrackingId']) {
  const trackerScriptEle = document.createElement('script');

  if (environment['gaTrackingId']) {
    trackerScriptEle.innerHTML = `
  (function(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function() {
      (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date();
    a = s.createElement(o),
      m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m)
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

  ga('create', \`${environment['gaTrackingId']}\`, 'auto');
`;
  } else if (environment['gtmTrackingId']) {
    trackerScriptEle.innerHTML = `
  window.dataLayer = [];
  (function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
        j.addEventListener('load', function() {
          var _ge = new CustomEvent('gtm_loaded', { bubbles: true });
          d.dispatchEvent(_ge);
        });
    j.async = true;
    j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl + '&gtm_auth=${
          environment['gtmAuth']
        }&gtm_preview=${environment['gtmPreview']}&gtm_cookies_win=x';
    f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', '${
      environment['gtmTrackingId']
    }');`;
  }

  document.head.appendChild(trackerScriptEle);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
