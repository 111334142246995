import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { StudioState } from '../intial-state';
import { PlanActions } from '../actions/plan.action';

@Injectable()
export class PlanEffects {
  @Effect()
  planSessionStorage$ = this._actions$.pipe(
    ofType(
      PlanActions.ADD_APPROVED_POLICY,
      PlanActions.ADD_AVALIABLE_PLANS,
      PlanActions.ADD_SELECTED_PLAN
    ),
    withLatestFrom(this.store$),
    map(([, storeState]) => {
      sessionStorage.setItem('plan', JSON.stringify(storeState.plan));
      return {
        type: PlanActions.ADD_PLAN_TO_SESSION_STORAGE,
        payload: false,
      };
    })
  );

  @Effect()
  addonsSessionStorage$ = this._actions$.pipe(
    ofType(PlanActions.ADD_AVALIABLE_ADDONS, PlanActions.ADD_SELECTED_ADDONS),
    withLatestFrom(this.store$),
    map(([, storeState]) => {
      sessionStorage.setItem('plan', JSON.stringify(storeState.plan));
      return {
        type: PlanActions.ADD_ADDONS_TO_SESSION_STORAGE,
        payload: false,
      };
    })
  );

  @Effect()
  planSelected$ = this._actions$.pipe(
    ofType(PlanActions.ADD_SELECTED_PLAN),
    withLatestFrom(this.store$),
    map(([action, storeState]: [any, any]) => {
      return {
        type: PlanActions.ADD_TOTAL_PREMIUM,
        payload: (
          parseFloat(action.payload.selectedPlan.price) +
          parseFloat(
            storeState.plan.selectedAddons
              ? storeState.plan.selectedAddons.reduce(
                  (a, b) => (+a + +b.price).toFixed(2),
                  0
                )
              : 0
          )
        ).toFixed(2),
      };
    })
  );

  @Effect()
  coveragesSelected$ = this._actions$.pipe(
    ofType(PlanActions.ADD_SELECTED_COVERAGE),
    withLatestFrom(this.store$),
    map(([action, storeState]: [any, any]) => {
      return {
        type: PlanActions.ADD_PLAN_STATE,
        payload: {
          totalPremium: parseFloat(
            storeState.plan.selectedCoverages
              ? storeState.plan.selectedCoverages.selectedCovers
                  .map((item) => item.amount)
                  .reduce((a, b) => a + b, 0)
              : 0
          ).toFixed(2),
          productConfigIDs: storeState.plan.selectedCoverages
            ? storeState.plan.selectedCoverages.selectedCovers.map(
                (item) => item.productConfigID
              )
            : [],
          selectedPlan: {
            planName: storeState.plan.selectedCoverages
              ? storeState.plan.selectedCoverages.selectedCovers.map(
                  (item) => item.name
                )
              : '',
            frequency: storeState.plan.selectedCoverages
              ? storeState.plan.selectedCoverages.frequency
              : '',
          },
        },
      };
    })
  );

  @Effect()
  addonSelected$ = this._actions$.pipe(
    ofType(PlanActions.ADD_SELECTED_ADDONS),
    withLatestFrom(this.store$),
    map(([action, storeState]: [any, any]) => {
      return {
        type: PlanActions.ADD_TOTAL_PREMIUM,
        payload: (
          parseFloat(storeState.plan.selectedPlan.price) +
          parseFloat(
            action.payload.selectedAddons
              ? storeState.plan.selectedAddons.reduce(
                  (a, b) => (+a + +b.price).toFixed(2),
                  0
                )
              : 0
          )
        ).toFixed(2),
      };
    })
  );

  @Effect()
  avaliableSmeBuilder$ = this._actions$.pipe(
    ofType(PlanActions.ADD_AVALIABLE_SME_BUILDER),
    withLatestFrom(this.store$),
    map(([action, storeState]: [any, any]) => {
      sessionStorage.setItem('plan', JSON.stringify(storeState.plan));
      return {
        type: PlanActions.ADD_AVALIABLE_SME_BUILDER_TO_SESSION_STORAGE,
        payload: null,
      };
    })
  );

  @Effect()
  smeBuilderSelected$ = this._actions$.pipe(
    ofType(PlanActions.ADD_SELECTED_SME_BUILDER),
    withLatestFrom(this.store$),
    map(([action, storeState]: [any, any]) => {
      const savedSmeBuilder = storeState.plan['savedSmeBuilder'];
      const productConfigIds = [
        ...(savedSmeBuilder ? savedSmeBuilder.selectedCoverages || [] : []),
        ...(savedSmeBuilder ? savedSmeBuilder.selectedAddons || [] : []),
      ].map((x) => x.productConfigId);
      sessionStorage.setItem('plan', JSON.stringify(storeState.plan));
      return {
        type: PlanActions.ADD_PRODUCT_CONFIG_ID,
        payload: productConfigIds,
      };
    })
  );

  @Effect()
  plan$ = this._actions$.pipe(
    ofType(PlanActions.ADD_ADDITIONAL_CHARGE),
    withLatestFrom(this.store$),
    map(([action, storeState]: [any, any]) => {
      return {
        type: PlanActions.ADD_TOTAL_ADDITIONAL_CHARGE,
        payload: (
          parseFloat(storeState.plan.totalPremium) + parseFloat(action.payload)
        ).toFixed(2),
      };
    })
  );

  constructor(private _actions$: Actions, private store$: Store<StudioState>) {}
}
