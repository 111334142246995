import { Action } from '@ngrx/store';

export enum AdditionalDataActions {
  ADD_QUERY_PARAMS = '[ADDITIONAL DATA] ADD QUERY PARAMS',
}

export class AddQueryParamsAction implements Action {
  readonly type: string = AdditionalDataActions.ADD_QUERY_PARAMS;
  constructor(public payload: any) {}
}

export type AdditionalDataActionsType = AddQueryParamsAction;
