import { Routes } from '@angular/router';
import { LayoutComponent } from './layout';
import { AppContextGuard, RouteGuard } from './guards';
import { environment } from '../environments/environment';
import { PhoneSelectGuardService } from './services/phone-select-guard.service';
import { AuthGuardService } from './services/auth-guard.service';
import { PageResolver } from './guards/page-resolver';
import { PrefetchDataResolver } from './resolver/prefetch-data.resolver';
import { OtpGuardService } from './services/otp-guard.service';
import { PageGuardService } from './services/page-guard.service';
import { ExpiryGuardService } from './services/expiry-guard.service';
import { QueryParamsGuard } from './guards/query-params.guard';
import { StateGuardService } from './services/state.guard.service';
import { S6PriceInfoResolver } from './resolver/s6-price-info.resolver';
import { PlanGuard } from './guards/plan-guard';
import { MaintenanceGuard } from './services/maintenance-guard.service';

export const childRoutes: Routes = [
  {
    path: '',
    redirectTo: environment.uri.home,
    pathMatch: 'full',
  },
  {
    path: environment.uri.home,
    canActivate: [
      PageResolver,
      PageGuardService,
      MaintenanceGuard,
      ExpiryGuardService,
    ],
    resolve: { data: PrefetchDataResolver },
    data: { appContextPath: 'pages.home.content.prefetchData' },
    loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule)
  },
  {
    path: environment.uri.otp,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      PageGuardService,
      MaintenanceGuard,
      OtpGuardService,
    ],
    loadChildren: () => import('./components/otp/otp.module').then(m => m.OtpModule)
  },
  {
    path: environment.uri.viettelOtp,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      PageGuardService,
      MaintenanceGuard,
      OtpGuardService,
    ],
    loadChildren: () => import('./components/viettel-otp/viettel-otp.module').then(m => m.ViettelOtpModule)
  },
  {
    path: environment.uri.phoneSelect,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      PageGuardService,
      MaintenanceGuard,
      PhoneSelectGuardService,
    ],
    loadChildren: () => import('./components/phone-select/phone-select.module').then(m => m.PhoneSelectModule)
  },
  {
    path: environment.uri.smeBuilder,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      PageGuardService,
      MaintenanceGuard,
      AuthGuardService,
    ],
    loadChildren: () => import('./components/sme-builder/sme-builder.module').then(m => m.SmeBuilderModule)
  },
  {
    path: environment.uri.builder,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      PageGuardService,
      MaintenanceGuard,
    ],
    loadChildren: () => import('./components/builder/builder.module').then(m => m.BuilderModule)
  },
  {
    path: environment.uri.quote,
    resolve: { data: PrefetchDataResolver },
    data: { appContextPath: 'pages.quote.content.prefetchData' },
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      PageGuardService,
      MaintenanceGuard,
    ],
    loadChildren: () => import('./components/quote/quote.module').then(m => m.QuoteModule)
  },
  {
    path: environment.uri.screener,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      PageGuardService,
      MaintenanceGuard,
    ],
    loadChildren: () => import('./components/screener/screener.module').then(m => m.ScreenerModule)
  },
  {
    path: environment.uri.coverage,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      AuthGuardService,
      MaintenanceGuard,
    ],
    loadChildren: () => import('./components/coverage/coverage.module').then(m => m.CoverageModule)
  },
  {
    path: environment.uri.plan,
    canActivate: [PageResolver, ExpiryGuardService, MaintenanceGuard],
    canActivateChild: [PlanGuard],
    loadChildren: () => import('./components/plan/plan.module').then(m => m.PlanModule)
  },
  {
    path: environment.uri.addons,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      AuthGuardService,
      MaintenanceGuard,
    ],
    loadChildren: () => import('./components/addons/addons.module').then(m => m.AddonsModule)
  },
  {
    path: environment.uri.applicantDetails,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      AuthGuardService,
      MaintenanceGuard,
    ],
    resolve: { data: PrefetchDataResolver },
    data: { appContextPath: 'pages.details.content.prefetchData' },
    loadChildren: () => import('./components/details/details.module').then(m => m.DetailsModule)
  },
  {
    path: environment.uri.review,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      AuthGuardService,
      MaintenanceGuard,
    ],
    resolve: { data: S6PriceInfoResolver },
    loadChildren: () => import('./components/review/review.module').then(m => m.ReviewModule)
  },
  {
    path: environment.uri.payment,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      AuthGuardService,
      MaintenanceGuard,
    ],
    resolve: { data: S6PriceInfoResolver },
    loadChildren: () => import('./components/payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: environment.uri.dokuPayment,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      AuthGuardService,
      MaintenanceGuard,
    ],
    loadChildren: () => import('./components/payment/doku/doku.module').then(m => m.DokuPaymentModule)
  },
  {
    path: environment.uri.dokuRecurring,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      AuthGuardService,
      MaintenanceGuard,
    ],
    loadChildren: () => import('./components/payment/doku-recurring/doku-recurring.module').then(m => m.DokuRecurringModule)
  },
  {
    path: environment.uri.paydollar,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      AuthGuardService,
      MaintenanceGuard,
    ],
    loadChildren: () => import('./components/payment/paydollar/paydollar.module').then(m => m.PayDollarModule)
  },
  {
    path: environment.uri.checkout,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      AuthGuardService,
      MaintenanceGuard,
    ],
    loadChildren: () => import('./components/payment/checkout/checkout.module').then(m => m.CheckOutModule)
  },
  {
    path: environment.uri.chinaPayment,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      AuthGuardService,
      MaintenanceGuard,
    ],
    loadChildren: () => import('./components/payment/china-payment/china-payment.module').then(m => m.ChinaPaymentModule)
  },
  {
    path: environment.uri.success,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      AuthGuardService,
      MaintenanceGuard,
    ],
    loadChildren: () => import('./components/success/success.module').then(m => m.SuccessModule)
  },
  {
    path: environment.uri.imei,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      PageGuardService,
      MaintenanceGuard,
    ],
    loadChildren: () => import('./components/imei/imei.module').then(m => m.ImeiModule)
  },
  {
    path: environment.uri.help,
    canActivate: [PageResolver, ExpiryGuardService, MaintenanceGuard],
    loadChildren: () => import('./components/help/help.module').then(m => m.HelpModule)
  },
  {
    path: environment.uri.leadgen,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      PageGuardService,
      MaintenanceGuard,
    ],
    loadChildren: () => import('./components/lead-gen/lead-gen.module').then(m => m.LeadGenModule)
  },
  {
    path: environment.uri.contactUs,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      PageGuardService,
      MaintenanceGuard,
    ],
    loadChildren: () => import('./components/contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: environment.uri.unsubscribe,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      PageGuardService,
      MaintenanceGuard,
    ],
    loadChildren: () => import('./components/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule)
  },
  {
    path: environment.uri.survey,
    canActivate: [
      PageResolver,
      ExpiryGuardService,
      PageGuardService,
      MaintenanceGuard,
    ],
    loadChildren: () => import('./components/survey/survey.module').then(m => m.SurveyModule)
  },
  // {
  //   path: environment.uri.screenerDetails,
  //   canActivate: [
  //     PageResolver,
  //     ExpiryGuardService,
  //     PageGuardService,
  //     MaintenanceGuard,
  //   ],
  //   loadChildren: () => import('projects/studio-widgets/src/lib/widgets/screener-details/screener-details.module').then(m => m.ScreenerDetailsModule)
  // },
  {
    path: environment.uri.error,
    canActivate: [PageResolver],
    loadChildren: () => import('./components/error/error.module').then(m => m.ErrorModule)
  },
  {
    path: environment.uri.expired,
    canActivate: [PageResolver],
    loadChildren: () => import('./components/expired/expired.module').then(m => m.ExpiredModule)
  },
  {
    path: environment.uri.retrieveQuote,
    canActivate: [PageResolver, StateGuardService, MaintenanceGuard],
    loadChildren: () => import('./components/retrieve-quote/retrieve-quote.module').then(m => m.RetrieveQuoteModule)
  },
];

export const appRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AppContextGuard],
    canActivateChild: [RouteGuard],
    children: childRoutes,
  },
  {
    path: ':country/:partner/:product/:channel/:locale',
    component: LayoutComponent,
    canActivate: [AppContextGuard, StateGuardService],
    canActivateChild: [RouteGuard, QueryParamsGuard],
    children: childRoutes,
  },
  {
    path: environment.uri.notFound,
    loadChildren: () => import('./components/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  },
  {
    path: environment.uri.maintenance,
    loadChildren: () => import('./components/maintenance/maintenance.module').then(m => m.MaintenanceModule)
  },
  { path: '**', redirectTo: environment.uri.notFound, pathMatch: 'full' },
];
