import { LAZY_LOAD_MODULES } from './lazy-load-modules';
import {
  ApplicationRef,
  Compiler,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  NgModuleFactory,
  NgModuleRef,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { ModuleWithRoot } from './module-with-root.type';
import { LoadingPlaceholderComponent } from './loading-placeholder/loading-placeholder.component';
import { LoadModuleContext } from './lazy-module.context';
import { AppContextService } from '../../app.context.service';

import { StudioState } from '../../state/intial-state';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { WidgetOutputEvent } from '..//../components/shared/widget/widget-output-event.model';
import { getModuleRegisrty } from '../../app.module';

@Directive({
  selector: '[studioLoadModule]',
})
export class LoadModuleDirective implements OnInit, OnDestroy {
  @Input('studioLoadModule') moduleName: keyof LAZY_LOAD_MODULES;

  // tslint:disable-next-line:no-input-rename
  @Input('studioLoadModuleContext') context: LoadModuleContext;

  /**
   * Emits readiness status of widget when it's being rendered
   */
  @Output() ready = new EventEmitter<boolean>();

  @Output() widgetOutput = new EventEmitter<WidgetOutputEvent>();

  private moduleRef: NgModuleRef<any>;

  constructor(
    private _elementRef: ElementRef,
    private _appRef: ApplicationRef,
    private _compiler: Compiler,
    private _cfr: ComponentFactoryResolver,
    private _viewContainer: ViewContainerRef,
    private _injector: Injector,
    private _appContext: AppContextService,
    /** @store changes **/
    private store: Store<StudioState>
  ) {}

  ngOnInit() {
    this.createLoadingPlaceholder(this._cfr, this._appRef, this._elementRef);

    try {
      const modulesMap = this._appContext.get(`moduleRegistry`);

      if (!modulesMap.hasOwnProperty(this.moduleName)) {
        throw new Error(
          `Lazy loaded module '${this.moduleName}' was not found!`
        );
      }
      const module = modulesMap[this.moduleName];
      const path = {
        moduleName: module.split('#')[1],
        modulePath: module.split('#')[0],
      };
      const importRegistry = getModuleRegisrty(
        path.moduleName,
        path.modulePath
      );
      importRegistry().then((x) => {
        // tslint:disable-next-line: no-shadowed-variable
        const module = x[path.moduleName];
        this._compiler
          .compileModuleAsync(module)
          .then((moduleFactory: NgModuleFactory<ModuleWithRoot>) => {
            this.moduleRef = moduleFactory.create(this._injector);
            const factory = this.moduleRef.componentFactoryResolver.resolveComponentFactory(
              (module as ModuleWithRoot).rootComponent
            );
            this._elementRef.nativeElement.children[0].remove();
            const comp = this._viewContainer.createComponent(factory);
            if (this.context) {
              this.mapContextToComponentInstance(comp.instance, this.context);
            }
            this.ready.emit(true);
          });
      });
    } catch (err) {
      throw err;
    }
  }

  ngOnDestroy(): void {
    if (this.moduleRef) {
      this.moduleRef.destroy();
    }
  }

  private createLoadingPlaceholder(
    cfr: ComponentFactoryResolver,
    appRef: ApplicationRef,
    elementRef: ElementRef
  ): void {
    const loadingPlaceholder = cfr
      .resolveComponentFactory(LoadingPlaceholderComponent)
      .create(this._injector);

    appRef.attachView(loadingPlaceholder.hostView);

    const domElem = (loadingPlaceholder.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    elementRef.nativeElement.appendChild(domElem);
  }

  private mapContextToComponentInstance(
    componentInstance: any,
    context: LoadModuleContext
  ): void {
    for (const option in context) {
      if (context.hasOwnProperty(option)) {
        if (option === 'useState') {
          const stateMap = context[option];
          for (const stateProp in stateMap) {
            if (stateMap.hasOwnProperty(stateProp)) {
              componentInstance[stateProp] = this.store.pipe(
                select(stateMap[stateProp])
              );
            }
          }
        } else if (option === 'output') {
          context[option] = componentInstance[option] = new EventEmitter();
          context[option].subscribe((widgetOutput: WidgetOutputEvent) => {
            this.widgetOutput.emit(widgetOutput);
          });
        } else {
          componentInstance[option] = context[option];
        }
      }
    }
  }
}
