import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { DialogService } from './dialog.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '@crux/components/pipes';

@NgModule({
  imports: [CommonModule, MatDialogModule, PipesModule, FlexLayoutModule],
  declarations: [DialogComponent],
  providers: [DialogService],
  entryComponents: [DialogComponent],
})
export class DialogModule {}
