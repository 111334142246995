import {
  ApplicantDetailsActions,
  ApplicantDetailsActionTypes,
} from '../actions/applicant-details.action';
import { format, parse } from 'date-fns';
import { cloneDeep } from 'lodash';

export function applicantDetailsReducer(
  state = {},
  action: ApplicantDetailsActionTypes
) {
  let actionData: ApplicantDetailsActionTypes;
  actionData = cloneDeep(action);
  switch (action.type) {
    case ApplicantDetailsActions.ADD_APPLICANT_DETAILS:
      for (const [key, value] of Object.entries(action.payload)) {
        if (value && (value._isAMomentObject || value instanceof Date)) {
          if (value._isAMomentObject) {
            actionData.payload[key] = value.format('YYYY-MM-DDTHH:mm:ss.SSS');
          } else if (value instanceof Date) {
            actionData.payload[key] = format(
              value,
              'yyyy-MM-dd\'T\'HH:mm:ss.SSS'
            );
          }
        }
      }
      action = actionData;
      return { ...action.payload };
    case ApplicantDetailsActions.ADD_NEW_KEY_TO_APPLICANT_DETAILS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
