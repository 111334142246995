import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  PartnerConfigActions,
  ChangePurchaseStepsLanguage,
  LoadPurchaseStepsSuccess,
} from '../actions';
import { AppService } from '../../services/app.service';
import { AppContextService } from '../../app.context.service';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Store } from '@ngrx/store';
import { StudioState } from '../intial-state';
import { isEmpty } from 'lodash';

declare function cssVars(): any;

@Injectable()
export class PartnerEffects {
  private renderer: Renderer2;

  @Effect()
  loadPartnerStyles$ = this._actions$.pipe(
    ofType(PartnerConfigActions.PARTNER_CONFIG_SUCCESS),
    withLatestFrom(this._store),
    map(([, storeState]) => {
      const styleVariables = this._appContext.get('common.styleVariables');
      let css = ':root {';
      for (const key in styleVariables) {
        if (styleVariables.hasOwnProperty(key)) {
          css += `--${key}: ${styleVariables[key]};`;
        }
      }
      css += '}';
      const style = this.document.createElement('style');
      style.innerHTML = css;
      this.document.head.appendChild(style);

      // IE / Edge Browser
      if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
        cssVars();
      }

      this.titleService.setTitle(this._appContext.get(`common.branding.title`));

      if (this._appService.isChubbBranded()) {
        this.renderer.addClass(this.document.body, 'chubb-branded');
      } else {
        this.document
          .getElementById('appFavicon')
          .setAttribute('href', '/assets/favicons/whitelabel-favicon.ico');
      }

      const initialState = this._appContext.get(
        'common.initialState.planSteps'
      );

      if (isEmpty(storeState['state'])) {
        this._store.dispatch(new ChangePurchaseStepsLanguage(initialState));

        this._store.dispatch(new LoadPurchaseStepsSuccess(initialState));
      }

      return {
        type: PartnerConfigActions.PARTNER_STYLE_LOAD_SUCCESS,
      };
    })
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<StudioState>,
    private _appService: AppService,
    private _appContext: AppContextService,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory2: RendererFactory2
  ) {
    this.renderer = rendererFactory2.createRenderer(null, null);
  }
}
