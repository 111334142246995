import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/**
 * Component Imports
 */
import { AppNavbarModule } from './navbar/navbar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaModule,
  RecaptchaSettings,
  RecaptchaFormsModule,
} from 'ng-recaptcha';
import { environment } from '../../environments/environment';
import { LoadModuleModule } from '../directives/load-module/load-module.module';
import { SectionModule } from './shared/section/section.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ButtonModule } from '@crux/components/button';
import { InputModule } from '@crux/components/input';
import { CruxCheckboxModule } from '@crux/components/checkbox';
import { CruxDropdownModule } from '@crux/components/dropdown';
import { RadioModule } from '@crux/components/radio';
import { SpinnerModule } from '@crux/components/spinner';
import { CruxCalendarModule } from '@crux/components/calendar';
import { ButtonToggleModule } from '@crux/components/button-toggle';
import { ZipCodeModule } from '@crux/components/zipcode';
import { FormBuilderModule } from '@crux/components/form-builder';
import { AddressTypeaheadModule } from '@crux/components/address-typeahead';
import { FooterModule } from '@crux/components/footer';
import { CardModule } from '@crux/components/card';
import { CarouselModule } from '@crux/components/carousel';
import { CruxAccordionModule } from '@crux/components/accordion';

// ToDo: split it into small modules
@NgModule({
  imports: [
    FlexLayoutModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    /**
     * Component Modules
     */
    LoadModuleModule,
    CardModule,
    InputModule,
    FooterModule,

    // ToDo: remove. still used in registration
    AppNavbarModule,
    ButtonModule,
    CarouselModule,
    CruxCheckboxModule,
    CruxDropdownModule,
    RadioModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule,
    SpinnerModule,
    CruxCalendarModule,
    ButtonToggleModule,
    ZipCodeModule,
    MatInputModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatCheckboxModule,
    CruxAccordionModule,
    FormBuilderModule,
    AddressTypeaheadModule,
    SectionModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.siteKey } as RecaptchaSettings,
    },
  ],
  exports: [
    InputModule,
    /**
     * Footer
     */
    FooterModule,
    /**
     * Navbar
     */
    AppNavbarModule,
    /**
     * Button
     */
    ButtonModule,
    CruxCheckboxModule,
    MatTooltipModule,
    MatSelectModule,
    MatFormFieldModule,
  ],
  entryComponents: [],
})
export class ComponentsModule {}
