import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'studio-loading-placeholder',
  templateUrl: './loading-placeholder.component.html',
  styleUrls: ['./loading-placeholder.component.scss'],
})
export class LoadingPlaceholderComponent {
  constructor() {}
}
