import { EventEmitter } from '@angular/core';
import { Widget } from './shared/widget/widget.model';
import { Section, SectionContent, SectionReady } from './shared/section';

// ToDo: looks a bit ugly, have any ideas how to improve it?
/**
 * Base class for container components
 */
export abstract class ContainerBase {
  /**
   * Name of the event that is a dynamic object, containing event type and payload
   */
  private readonly _eventName = 'output';

  /**
   * Get event emitter for specified widget
   * @param {string} widgetName - Widget Name
   * @param {string} sectionKey - Key of section  where widget is located
   * @param {Section[]} sections - Collection of sections where to find widget
   * @protected
   * @return {EventEmitter<any>} Event emitter for specified widget
   */
  protected _getEventFor(
    widgetName: string,
    sectionKey: string,
    sections: Section[]
  ): EventEmitter<any> {
    const widget = this._findWidget(widgetName, sectionKey, sections);
    if (!widget) {
      return;
    }

    return this._findEventIn(widget);
  }

  /**
   * Find widget by name from specified section
   * @param {string} widgetName - Widget Name
   * @param {string} sectionKey - Key of section  where widget is located
   * @param {Section[]} sections - Collection of sections where to find widget
   * @protected
   * @return {Widget} - Widget that was found
   */
  protected _findWidget(
    widgetName: string,
    sectionKey: string,
    sections: Section[]
  ): Widget {
    const foundSection = sections.find((section: Section) => {
      return section.key === sectionKey;
    });

    if (!foundSection || !foundSection.content) {
      return null;
    }

    const foundSectionContent = foundSection.content.find(
      (sectionContent: SectionContent) => {
        return sectionContent.widget.name === widgetName;
      }
    );

    if (!foundSectionContent || !foundSectionContent.widget) {
      return null;
    }

    return foundSectionContent.widget;
  }

  /**
   * Get event from widget by event's name
   * @param {Widget} widget - Widget to look in
   * @param {string} eventEmitterName - Name of event emitter of Widget. Should match output parameter's name from widget and the one in studio.json
   * @protected
   * @return {EventEmitter<any>} - Event that was found
   */
  protected _findEventIn(widget: Widget): EventEmitter<any> {
    if (!widget.context) {
      return null;
    }

    if (!widget.context.hasOwnProperty(this._eventName)) {
      return null;
    }

    return widget.context[this._eventName];
  }

  /**
   * Check if widget was successfully loaded/rendered in container
   * @param {string} widgetName - Widget Name
   * @param {string} sectionKey - Key of section  where widget is located
   * @param {SectionReady} sectionReady - Section ready status from store
   * @protected
   * @return {boolean} - Either widget is ready or not
   */
  protected _widgetIsReady(
    widgetName: string,
    sectionKey: string,
    sectionReady: SectionReady
  ): boolean {
    return !(
      !sectionReady ||
      !sectionReady[sectionKey] ||
      !sectionReady[sectionKey][widgetName]
    );
  }
}
