import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { StudioState } from '../state/intial-state';
import { AppContextService } from '../app.context.service';
import { BaseUrlService } from '../services/base-url.service';
import { AddOtpData } from '../state/actions/otp.action';

@Injectable()
export class OtpGuardService implements CanActivate {
  constructor(private _store: Store<StudioState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const data = route.queryParams.data;
      if (!data) {
        return true;
      }
      this._store.dispatch(
        new AddOtpData(
          Object.assign(JSON.parse(atob(data)), {
            prefilled: true,
            queryParams: data,
          })
        )
      );
    } catch {
      return true;
    }
    return true;
  }
}
