import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { StudioState } from '../intial-state';
import { SaveQuoteActions } from '../actions/save-quote.action';
import { ApplicantDetailsActions } from '../actions/applicant-details.action';
import { isUndefined } from 'lodash-es';

@Injectable()
export class ApplicantDetailsEffects {
  @Effect()
  navigateToStep$ = this._actions$.pipe(
    ofType(ApplicantDetailsActions.ADD_APPLICANT_DETAILS),
    withLatestFrom(this.store$),
    map(([action, state]) => {
      const applicant = state.applicantDetails;
      const saveQuote = state.saveQuote;
      const applicantEnteredMobileNumber = applicant.mobileNumber
        ? applicant.mobileNumber.split(' ')[1]
        : undefined;
      for (const prop in applicant) {
        if (applicant.hasOwnProperty(prop)) {
          if (prop === 'firstName' || 'lastName' || 'mobileNumber' || 'email') {
            return {
              type: SaveQuoteActions.ADD_SAVE_QUOTE_DETAILS,
              payload: {
                firstName: applicant.firstName || saveQuote.firstName,
                lastName: applicant.lastName || saveQuote.lastName,
                mobileNumber:
                  applicantEnteredMobileNumber === 'undefined' ||
                  isUndefined(applicantEnteredMobileNumber)
                    ? saveQuote.mobileNumber
                    : applicant.mobileNumber,
                email: applicant.email || saveQuote.email,
              },
            };
          }
        }
      }
    })
  );

  constructor(private _actions$: Actions, private store$: Store<StudioState>) {}
}
