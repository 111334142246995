import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app-routes';
import { PageResolver } from './guards/page-resolver';
import { PrefetchDataResolver } from './resolver/prefetch-data.resolver';
import { S6PriceInfoResolver } from './resolver/s6-price-info.resolver';

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
  providers: [PageResolver, PrefetchDataResolver, S6PriceInfoResolver],
})
export class AppRoutingModule {}
