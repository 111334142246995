import { Injectable, Inject } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  PurchaseActions,
  NavigateNextStep,
  NavigatePreviousStep,
  NavigateToStep,
  NavigateToRoute,
} from '../actions';
import { STUDIO } from '../../const/studio-base-href';
import { POLICY_STATUS_CODE } from '../../app.constant';
import { LoadingService } from '../../components/shared/loading/loading.service';
import { DialogService } from '../../../../projects/studio-widgets/src/lib/shared/dialog/dialog.service';
import { AppContextService } from '../../app.context.service';
import { CommonUtils } from '../../utils/common.utils';

@Injectable()
export class PurchaseEffects {
  @Effect()
  navigateToStep$ = this._actions$.pipe(
    ofType(PurchaseActions.NAVIGATE_TO_STEP),
    tap((action: NavigateToStep) => {
      this._router.navigate(
        [this._studio.base_href + '/' + action.payload.id],
        action.payload.fragment ? { fragment: action.payload.fragment } : {}
      );
    }),
    map(() => ({
      type: PurchaseActions.NAVIGATE_TO_STEP_SUCCESS,
      payload: false,
    }))
  );

  @Effect()
  navigateNextStep$ = this._actions$.pipe(
    ofType(PurchaseActions.NAVIGATE_NEXT_STEP),
    tap((action: NavigateNextStep) => {
      this._router.navigate([this._studio.base_href + '/' + action.payload.id]);
    }),
    map(() => ({
      type: PurchaseActions.NAVIGATE_NEXT_STEP_SUCCESS,
      payload: false,
    }))
  );

  @Effect()
  navigatePreviousStep$ = this._actions$.pipe(
    ofType(PurchaseActions.NAVIGATE_PREVIOUS_STEP),
    tap((action: NavigatePreviousStep) => {
      this._router.navigate([this._studio.base_href + '/' + action.payload.id]);
    })
  );

  @Effect()
  navigateToRoute$ = this._actions$.pipe(
    ofType(PurchaseActions.NAVIGATE_TO_ROUTE),
    tap((action: NavigateToRoute) => {
      this._router.routeReuseStrategy.shouldReuseRoute = () => false;
      this._router.navigate(
        [this._studio.base_href + '/' + action.payload.id],
        action.payload.fragment ? { fragment: action.payload.fragment } : {}
      );
    }),
    map(() => ({
      type: PurchaseActions.NAVIGATE_TO_ROUTE_SUCCESS,
      payload: false,
    }))
  );

  @Effect()
  handlePurchaseError$ = this._actions$.pipe(
    ofType(PurchaseActions.HANDLE_PURCHASE_ERROR),
    tap((data) => {
      const errorType = CommonUtils.getErrorType(data['payload']);
      this._loaderService.close();
      const errors = this._appContext.get('pages.payment.content.errors');
      this._dialogService.open(
        errors[`${errorType}Title`],
        errors[`${errorType}Messages`],
        errors['closeLabel'],
        null,
        'payment-dialog-error'
      );
    }),
    map(() => ({
      type: PurchaseActions.HANDLE_PURCHASE_ERROR_SUCCESS,
      payload: false,
    }))
  );

  constructor(
    private _actions$: Actions,
    private _router: Router,
    private _loaderService: LoadingService,
    private _dialogService: DialogService,
    private _appContext: AppContextService,
    private _studio: STUDIO
  ) {}
}
