import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class LoadingService {
  private loaderSubject = new Subject<LoaderState>();
  loaderState: Observable<LoaderState> = this.loaderSubject.asObservable();

  constructor() {}

  open(title?: string, message?: string) {
    this.loaderSubject.next({
      show: true,
      message: message,
      title: title,
      mode: 'fullscreen',
    } as LoaderState);
  }

  openForContainer(title?: string, message?: string) {
    this.loaderSubject.next({
      show: true,
      message: message,
      title: title,
      mode: 'container',
    } as LoaderState);
  }

  close() {
    this.loaderSubject.next({ show: false } as LoaderState);
  }
}

export class LoaderState {
  show: boolean;
  title?: string;
  mode: 'fullscreen' | 'container' = 'fullscreen';
  message: string;
}
