import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarModule } from '@crux/components/navbar';
import { NavbarComponent } from './navbar.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    NavbarModule.forRoot({
      location: 'left',
    }),
  ],
  declarations: [NavbarComponent],
  exports: [NavbarModule, NavbarComponent],
})
export class AppNavbarModule {}
