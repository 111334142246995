import {
  Component,
  Input,
  ViewEncapsulation,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'studio-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PartnerComponent implements AfterViewInit {
  @Input() disclaimer: string;
  @Input() header: string;
  @Input() logo: string;
  @Input() showLogoColor = false;
  @Input() logoHeight: string;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.logoHeight) {
      Array.from(document.getElementsByClassName('partner-brand-icon')).forEach(
        (item: HTMLElement) => {
          item.style.maxHeight = this.logoHeight;
        }
      );
    }
  }
}
