import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom, debounce } from 'rxjs/operators';
import {
  LanguageConfigActions,
  LanguageChange,
} from '../actions/language.action';
import { LanguageSelectorComponent } from '../../components/shared/language-selector/language-selector.component';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { StudioState } from '../intial-state';
import { STUDIO } from '../../const/studio-base-href';
import { timer } from 'rxjs';
import { StateActions } from '../actions/state.action';
import { CommonUtils } from '../../utils/common.utils';
import { FontService } from './../../services/font.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class LanguageEffects {
  @Effect()
  loadLanguagePopup$ = this._actions$.pipe(
    ofType(LanguageConfigActions.LANGUAGE_CONFIG_SUCCESS),
    debounce(() => timer(800)),
    withLatestFrom(this._store),
    map(([data, storeState]) => {
      if (
        this._dialogRef instanceof MatDialogRef === false &&
        storeState.language.selectedLanguage === undefined
      ) {
        this._dialogRef = this.dialog.open(LanguageSelectorComponent, {
          data: data['payload'],
          panelClass: 'language-selector-overlay',
        });
        this._dialogRef.afterClosed().subscribe((selectedLanguage) => {
          if (selectedLanguage) {
            this._store.dispatch(new LanguageChange(selectedLanguage));
          }
        });

        this._dialogRef.backdropClick().subscribe(() => {
          this._store.dispatch(
            new LanguageChange(this._router.url.split('/')[5])
          );
        });
      }
      return {
        type: LanguageConfigActions.LANGUAGE_POPUP_LOAD_SUCCESS,
      };
    })
  );

  @Effect()
  changeLanguage$ = this._actions$.pipe(
    ofType(LanguageConfigActions.LANGUAGE_CHANGE),
    map((data) => {
      let path = this._router.url.split('/');
      let fragment = null;
      let query = null;
      if (this._router.url.includes('#')) {
        const fragmentPath = this._router.url.split('#');
        path = fragmentPath[0].split('/');
        fragment = fragmentPath[1];
      }
      if (this._router.url.includes('?')) {
        const queryPath = this._router.url.split('?');
        path = queryPath[0].split('/');
        query = queryPath[1];
      }
      if (path.length > 5 && path[5] !== data['payload']) {
        path[5] = data['payload'];
        this._studio.base_href = path.slice(0, 6).join('/');
        this._router.routeReuseStrategy.shouldReuseRoute = () => false;
        if (fragment) {
          this._router.navigate([path.join('/')], { fragment: fragment });
        } else if (query) {
          this._router.navigate([path.join('/')], {
            queryParams: CommonUtils.convertQueryParams(query),
          });
        } else {
          this._router.navigate([path.join('/')]);
        }
      }
      this._fontService.handleFont(path[5]);
      return {
        type: LanguageConfigActions.LANGUAGE_CHANGE_SUCCESS,
      };
    })
  );

  @Effect()
  stateRestore$ = this._actions$.pipe(
    ofType(StateActions.RESTORE_STATE),
    map(() => {
      if (typeof this._dialogRef.close === 'function') {
        this._dialogRef.close();
      }
      return {
        type: LanguageConfigActions.LANGUAGE_POPUP_CLOSE_SUCCESS,
      };
    })
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<StudioState>,
    public dialog: MatDialog,
    private _router: Router,
    private _studio: STUDIO,
    private _dialogRef: MatDialogRef<LanguageSelectorComponent>,
    private _fontService: FontService
  ) {}
}
