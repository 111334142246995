import { Injectable, Inject } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from '@angular/router';
import { STUDIO } from '../const/studio-base-href';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { StudioState } from '../state/intial-state';
import {
  AddSelectedPhoneMakeAndModel,
  AddSelectedPhoneImei,
} from '../state/actions/device-details.action';
import { AppService } from './app.service';
import { AppContextService } from '../app.context.service';

@Injectable()
export class PhoneSelectGuardService implements CanActivate {
  private hasPhoneParam = false;
  private isFirstLoad = true;
  private phoneDetailsParam = null;
  private _baseHref = this._studio.base_href;

  constructor(
    private router: Router,
    private _studio: STUDIO,
    private _store: Store<StudioState>,
    private route: ActivatedRoute,
    private _appService: AppService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let detailsCompleted = true;
    if (this.isFirstLoad) {
      this.phoneDetailsParam = this.route.snapshot.queryParamMap.get(
        'phone_details'
      );
      this.isFirstLoad = false;
    }

    if (!this.phoneDetailsParam && !this.hasPhoneParam) {
      return true;
    } else {
      try {
        const phoneDetails = JSON.parse(atob(this.phoneDetailsParam));
        this.hasPhoneParam = true;
        if (phoneDetails.brand && phoneDetails.model) {
          this._store.dispatch(
            new AddSelectedPhoneMakeAndModel({
              brand: phoneDetails.brand,
              model: phoneDetails.model,
              makeAndModelFromPartner: true,
            })
          );
        }

        if (phoneDetails.imei) {
          detailsCompleted = false;

          this._store.dispatch(
            new AddSelectedPhoneImei({
              imei: phoneDetails.imei,
              imeiFromPartner: true,
            })
          );

          this._appService
            .getImeiDetails(phoneDetails.imei)
            .toPromise()
            .then(
              (phoneInfo) => {
                try {
                  this._store.dispatch(
                    new AddSelectedPhoneMakeAndModel({
                      brand: phoneInfo['brand'],
                      model: phoneInfo['model'],
                    })
                  );

                  this.handleRouting();
                } catch (error) {
                  this.router.navigate([
                    `${this._baseHref}/${environment.uri.error}`,
                  ]);
                  console.error(error);
                }
              },
              () => {
                this.router.navigate([
                  `${this._baseHref}/${environment.uri.error}`,
                ]);
              }
            );
        }
        if (detailsCompleted) {
          this.handleRouting();
        }
      } catch {
        this.router.navigate([`${this._baseHref}/${environment.uri.error}`]);
      }
    }
    return false;
  }

  handleRouting() {
    if (this.router.url.endsWith(environment.uri.plan)) {
      this.router.navigate([`${this._baseHref}/${environment.uri.home}`]);
    } else {
      this.router.navigate([`${this._baseHref}/${environment.uri.plan}`]);
    }
  }
}
