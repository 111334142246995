import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  Router,
} from '@angular/router';
import * as _ from 'lodash';
import { AppContextService } from '../app.context.service';
import { AuthGuardService } from '../services/auth-guard.service';
import { BaseUrlService } from '../services/base-url.service';

@Injectable()
export class PlanGuard implements CanActivateChild {
  constructor(
    private _appContext: AppContextService,
    private _authService: AuthGuardService,
    private router: Router,
    private _bus: BaseUrlService
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this._authService.isAuthenticated()) {
      return true;
    }

    if (
      this._appContext.get(`pages.plan.content.additionalDataStore`) ===
      undefined
    ) {
      this._authService.isAuthenticated(true);
      return true;
    }

    this.router.navigate([this._bus.baseUri], {
      queryParamsHandling: 'preserve',
    });
    return false;
  }
}
