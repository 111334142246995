import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationButtonComponent } from './navigation-button.component';
import { MatButtonModule } from '@angular/material/button';
import { IconModule } from '@crux/components/icon';

@NgModule({
  imports: [CommonModule, IconModule, MatButtonModule],
  exports: [NavigationButtonComponent],
  declarations: [NavigationButtonComponent],
})
export class NavigationButtonModule {}
