import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionComponent } from './section.component';
import { WidgetModule } from '../widget/widget.module';

@NgModule({
  imports: [CommonModule, WidgetModule],
  declarations: [SectionComponent],
  exports: [SectionComponent],
})
export class SectionModule {
  constructor() {}
}
