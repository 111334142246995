import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currency' })
export class CurrencyPipe implements PipeTransform {
  transform(value: string, currency: string) {
    if (currency === '$') {
      if (value.startsWith('$')) {
        return value;
      }
      return `$${value}`;
    }
    if (value.startsWith('$')) {
      value = value.substring(1);
    }
    return `${value} ${currency}`;
  }
}
