import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { StudioState } from '../intial-state';
import { PlanActions } from '../actions/plan.action';
import { TenantActions } from '../actions/tenant.action';

@Injectable()
export class TenantEffects {
  @Effect()
  setTenantSessionStorage$ = this._actions$.pipe(
    ofType(TenantActions.TENANT_LOAD_SUCCESS),
    withLatestFrom(this.store$),
    map(([, storeState]) => {
      sessionStorage.setItem(`tenant`, storeState.tenant.name);
      return {
        type: TenantActions.TENANT_SESSION_STORAGE_SUCCESS,
        payload: false,
      };
    })
  );

  constructor(private _actions$: Actions, private store$: Store<StudioState>) {}
}
