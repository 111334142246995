import { Action } from '@ngrx/store';

export enum ContactUsDetailsActions {
  ADD_CONTACT_US_DETAILS = '[CONTACT US] ADD CONTACT US DETAILS',
  CLEAR_CONTACT_US_DETAILS = '[CONTACT US] CLEAR CONTACT US DETAILS',
}

export class AddContactUsDetails implements Action {
  readonly type: string = ContactUsDetailsActions.ADD_CONTACT_US_DETAILS;

  constructor(public payload: { [key: string]: any }) {}
}

export class ClearContactUsDetails implements Action {
  readonly type: string = ContactUsDetailsActions.CLEAR_CONTACT_US_DETAILS;

  constructor(public payload?: any) {}
}

export type ContactUsDetailsActionTypes =
  | AddContactUsDetails
  | ClearContactUsDetails;
