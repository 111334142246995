import {
  Component,
  Input,
  OnInit,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'csw-pdf-viewer-dialog',
  templateUrl: './pdf-viewer-dialog.component.html',
  styleUrls: ['./pdf-viewer-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PdfViewerDialogComponent implements OnInit {
  pdfSrc: string;
  linkCloseLabel: string;
  showLoader = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.pdfSrc = this.data.pdfSrc;
    this.linkCloseLabel = this.data.linkCloseLabel;
  }

  pdfLoaded(pdf: PDFDocumentProxy) {
    this.showLoader = false;
  }
}
