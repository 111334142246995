import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Section } from './section.model';
import { Store } from '@ngrx/store';
import { StudioState } from '../../../state/intial-state';
import {
  SectionsReadyDestroySuccessAction,
  SectionsReadySuccessAction,
} from '../../../state/actions/sections-ready.action';
import { WidgetOutputEvent } from '..//../../components/shared/widget/widget-output-event.model';

@Component({
  selector: 'studio-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SectionComponent implements OnDestroy {
  @Input() config: Section;

  @Output() widgetOutput: EventEmitter<any> = new EventEmitter<any>();

  private _sectionReadyStatus;

  constructor(private _store: Store<StudioState>) {}

  /**
   * Sets widget ready status to store
   * @param {string }widgetName - Name of the widget
   * @param {boolean} isReady - is widget loaded or not
   */
  sectionIsReady(widgetName: string, isReady: boolean): void {
    if (!this._sectionReadyStatus) {
      this._sectionReadyStatus = {
        [this.config.key]: { [widgetName]: isReady },
      };
    } else {
      this._sectionReadyStatus = {
        [this.config.key]: {
          ...this._sectionReadyStatus[this.config.key],
          ...{ [widgetName]: isReady },
        },
      };
    }

    this._store.dispatch(
      new SectionsReadySuccessAction(this._sectionReadyStatus)
    );
  }

  onWidgetOutput(widgetName: string, output: WidgetOutputEvent): void {
    this.widgetOutput.emit(output);
  }

  ngOnDestroy(): void {
    this._store.dispatch(
      new SectionsReadyDestroySuccessAction(this.config.key)
    );
  }
}
