import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  CanActivateChild,
  ActivatedRoute,
} from '@angular/router';
import { AppContextService } from '@studio/app.context.service';
import { environment } from '../../environments/environment';
import { BaseUrlService } from '@studio/services/base-url.service';

@Injectable()
export class RouteGuard implements CanActivateChild {
  constructor(
    private _router: Router,
    private _appContext: AppContextService,
    private _bus: BaseUrlService
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return true;

    // const activeRoutes = this._appContext.get('common.routes.active');
    // if (!activeRoutes) {
    //   return false;
    // }

    // let path = childRoute.routeConfig.path;

    // if (path && path.length > 0) {
    //   if (this._bus.baseUri && this._bus.baseUri.length > 1) {
    //     path = path.replace(this._bus.baseUri, '');
    //   }

    //   if (activeRoutes.includes(path)) {
    //     return true;
    //   } else {
    //     this._router.navigate([environment.uri.notFound]);
    //     return false;
    //   }
    // } else {
    //   return true;
    // }
  }
}
