import {
  TenantActions,
  TenantActionTypes,
  TenantLoadFailure,
  TenantLoadSuccess,
} from '../actions/tenant.action';

export function tenantReducer(state = {}, action: TenantActionTypes) {
  switch (action.type) {
    case TenantActions.TENANT_LOAD_SUCCESS:
      return { ...state, ...(<TenantLoadSuccess>action).payload };
    case TenantActions.TENANT_LOAD_FAILURE:
      return { ...state, ...(<TenantLoadFailure>action).payload };
    default:
      return state;
  }
}
