import { Action } from '@ngrx/store';

export enum SaveQuoteActions {
  ADD_SAVE_QUOTE_DETAILS = '[SAVE QUOTE] ADD SAVE QUOTE DETAILS',
}

export class AddSaveQuoteDetails implements Action {
  readonly type: string = SaveQuoteActions.ADD_SAVE_QUOTE_DETAILS;

  constructor(public payload: { [key: string]: any }) {}
}

export type SaveQuoteActionTypes = AddSaveQuoteDetails;
