import { POLICY_STATUS_CODE, PASSPHRASE } from '../app.constant';
import * as CryptoJS from 'crypto-js';

export class CommonUtils {
  public static getPropertyByKeyPath(targetObj, keyPath) {
    let keys = keyPath.split('.');
    if (keys.length === 0) {
      return undefined;
    }
    keys = keys.reverse();
    let subObject = targetObj;
    while (keys.length) {
      const k = keys.pop();
      if (!subObject.hasOwnProperty(k)) {
        return undefined;
      } else {
        subObject = subObject[k];
      }
    }
    return subObject;
  }

  public static getAdditionalDataStore(targetObj, additionalDataStore) {
    const isArray = Array.isArray(additionalDataStore);

    if (isArray) {
      let storeData = {};
      additionalDataStore.forEach((keyPath) => {
        storeData = Object.assign(
          storeData,
          Array.isArray(keyPath)
            ? { [keyPath[0]]: this.getPropertyByKeyPath(targetObj, keyPath[1]) }
            : this.getPropertyByKeyPath(targetObj, keyPath)
        );
      });
      return storeData;
    } else {
      return this.getPropertyByKeyPath(targetObj, additionalDataStore);
    }
  }

  public static evaluate(
    functionOrCode,
    data = null,
    onlyEvaluteWithoutReturn = false
  ) {
    if (data) {
      (<any>window).tempData = data;
      return (<any>window)
        .Function(`data = tempData; return ${functionOrCode}`)
        .call(null);
    }
    if (onlyEvaluteWithoutReturn) {
      return (<any>window).Function(`${functionOrCode}`).call(null);
    }
    return (<any>window).Function(`return ${functionOrCode}`).call(null);
  }

  public static convertQueryParams(queryParams) {
    const params = queryParams.split('&');
    let pair = null;
    const data = {};
    params.forEach((d) => {
      pair = d.split('=');
      data[`${pair[0]}`] = pair[1];
    });
    return data;
  }

  public static stringify(obj_from_json) {
    if (typeof obj_from_json !== 'object' || Array.isArray(obj_from_json)) {
      return JSON.stringify(obj_from_json);
    }
    const props = Object.keys(obj_from_json)
      .map((key) => `${key}:${this.stringify(obj_from_json[key])}`)
      .join(',');
    return `{${props}}`;
  }

  public static getErrorType(statusCode) {
    switch (statusCode) {
      case POLICY_STATUS_CODE.UNKNOWN_PAYMENT_EXCEPTION: {
        return 'unknownPayment';
      }
      case POLICY_STATUS_CODE.DUPLICATE_PAYMENT_EXCEPTION: {
        return 'duplicatePayment';
      }
      case POLICY_STATUS_CODE.INVALID_PAYMENT_EXCEPTION: {
        return 'invalidPayment';
      }
      case POLICY_STATUS_CODE.DUPLICATE_POLICY_HOLDER_EXCEPTION: {
        return 'duplicatePolicyHolder';
      }
      case POLICY_STATUS_CODE.DUPLICATE_PRODUCT_DATA_EXCEPTION: {
        return 'duplicateProductData';
      }
      case POLICY_STATUS_CODE.PREMIUM_CHECK_FAILED_EXCEPTION: {
        return 'premiumCheckFailed';
      }
      case POLICY_STATUS_CODE.SANCTION_CHECK_FAILED_EXCEPTION: {
        return 'sanctionCheckFail';
      }
      default: {
        return 'generalError';
      }
    }
  }

  public static disableScroll() {
    const scrollTop = document.documentElement.scrollTop;
    const scrollLeft = document.documentElement.scrollLeft;
    window.onscroll = function() {
      window.scrollTo(scrollLeft, scrollTop);
    };
  }

  public static enableScroll(timeout = null) {
    setTimeout(() => {
      window.onscroll = null;
    }, timeout);
  }

  public static encryptData(data: any) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), PASSPHRASE).toString();
    } catch (e) {
      console.log('Encryption failed', e);
    }
  }

  public static decryptData(data: any) {
    try {
      const decryptedData = CryptoJS.AES.decrypt(data, PASSPHRASE);
      if (decryptedData.toString()) {
        return JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log('Decryption failed', e);
    }
  }

  public static flattenObj(obj: any, res = {}) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const propName = key;
        if (typeof obj[key] === 'object') {
          CommonUtils.flattenObj(obj[key], res);
        } else {
          res[propName] = obj[key];
        }
      }
    }
    return res;
  }
}
