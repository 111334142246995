import { Action } from '@ngrx/store';

export enum LeadGenDetailsActions {
  ADD_LEAD_GEN_DETAILS = '[LEAD GEN] ADD LEAD GEN DETAILS',
  CLEAR_LEAD_GEN_DETAILS = '[LEAD GEN] CLEAR LEAD GEN DETAILS',
}

export class AddLeadGenDetails implements Action {
  readonly type: string = LeadGenDetailsActions.ADD_LEAD_GEN_DETAILS;

  constructor(public payload: { [key: string]: any }) {}
}

export class ClearLeadGenDetails implements Action {
  readonly type: string = LeadGenDetailsActions.CLEAR_LEAD_GEN_DETAILS;

  constructor(public payload?: any) {}
}

export type LeadGenDetailsActionTypes = AddLeadGenDetails | ClearLeadGenDetails;
