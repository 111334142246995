import { Injectable, Injector } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '../components/shared/toast/toast.service';
import { StudioHttpResponseError } from './studio-http-response.error';

@Injectable({
  providedIn: 'root',
})
export class StudioErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse): any {
    const toast = this.injector.get(ToastService);
    if (error instanceof HttpErrorResponse) {
      if (!navigator.onLine) {
        console.log('offline');
      } else {
        toast.showError(
          `We couldn't process this request. Please try again later. (${
            (<HttpErrorResponse>error).status
          })`
        );
      }
    } else {
      switch (error.name) {
        case 'StudioError':
          toast.showError(error.message);
          break;
        case 'StudioHttpResponseError':
          const studioHttpResponseError = <StudioHttpResponseError>error;
          toast.showError(
            `${studioHttpResponseError.message} ${
              studioHttpResponseError.httpErrorResponse
                ? '(' + studioHttpResponseError.httpErrorResponse.status + ')'
                : ''
            }`
          );
          break;
        default:
          toast.showError(
            `Oop! We didn't expect that to happen. Please try again later.`
          );
          break;
      }
    }

    console.error(error);
  }
}
