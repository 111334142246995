import { Injectable, Injector } from '@angular/core';

// Util Imports
import _get from 'lodash-es/get';
import _merge from 'lodash-es/merge';
import _some from 'lodash-es/some';
import _set from 'lodash-es/set';
import * as STUDIOJS from '../../studio';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { concatMap, mergeMap } from 'rxjs/operators';
import { filter, map, catchError } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { isEmpty } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
/**
 * @todo Add methods `isContextReady` and provide util
 * to store the context in desired store like session storage or
 * local storage or cache it through service worker
 */
export class AppContextService {
  private baseUrl = sessionStorage.getItem('blobUrl') || environment.blobUrl;

  constructor(
    private injector: Injector,
    private _http: HttpClient,
    private _title: Title,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {}

  private _appContext: any;

  get(context: string): any {
    return _get(this._appContext, context);
  }

  getSelfUrls(value, key) {
    const studioConfig$ = [];
    if (value.hasOwnProperty('__$$selfUrl')) {
      studioConfig$.push(
        this.getSelfJson(this._appContext, value['__$$selfUrl'], key)
      );
    }

    if (_some(value, '__$$selfUrl')) {
      const childWithSelfUrl = this.childHasSelfUrl(value, key);

      for (const child of childWithSelfUrl) {
        const selfUrl = _get(this._appContext, `${child}.__$$selfUrl`);
        studioConfig$.push(this.getSelfJson(this._appContext, selfUrl, child));
      }
    }

    return studioConfig$;
  }

  load(configPath?: string): Observable<any> {
    return this._http.get(this.baseUrl + configPath + '/studio.json').pipe(
      concatMap((res) => {
        const studioConfig$: Observable<any>[] = [];
        this._appContext = this.merge(res, STUDIOJS.default);

        for (const [key, value] of Object.entries(res)) {
          if (key !== 'pages') {
            const x = this.getSelfUrls(value, key);
            studioConfig$.push(...x);
          }
        }

        return isEmpty(studioConfig$) ? of(true) : forkJoin(studioConfig$);
      }),
      map(() => this._appContext),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  private merge(jsonObject: any, jsObject: any): any {
    let mergedObj: any = {};
    if (jsObject && jsonObject) {
      mergedObj = _merge(jsonObject, jsObject);
    } else if (jsObject) {
      mergedObj = jsObject;
    } else if (jsonObject) {
      mergedObj = jsonObject;
    }

    return mergedObj;
  }

  private childHasSelfUrl(obj, parentKey) {
    const selfUrlChildren = [];
    // delete obj.locale;
    for (const [key, value] of Object.entries(obj)) {
      if (value.hasOwnProperty('__$$selfUrl')) {
        selfUrlChildren.push(`${parentKey}.${key}`);
      }
    }
    return selfUrlChildren;
  }

  private getSelfJson(
    appContext: any,
    path: string,
    key: string
  ): Observable<any> {
    return this._http.get(path).pipe(
      mergeMap((partnerJson) => {
        if (partnerJson.hasOwnProperty('__$$defaultUrl')) {
          return this.getDefaultJson(partnerJson['__$$defaultUrl']).then(
            (defaultJson) => {
              delete partnerJson['__$$defaultUrl'];
              const combinedSelfJson = _merge(defaultJson, partnerJson);
              _set(appContext, key, combinedSelfJson);
            }
          );
        } else {
          _set(appContext, key, partnerJson);
          return of(partnerJson);
        }
      })
    );
  }

  private getDefaultJson(path: string) {
    return this._http.get(path).toPromise();
  }

  /**
   * @todo Use trackRouterEvents to track changes
   * in route and update the title accordingly
   */
  trackRouterEvents(): void {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this._activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => this.setTitle(event['title']));
  }

  /**
   * @todo Use this to set the title of the app
   * when routes change
   */
  setTitle(title: string): void {
    const appTitle = `${this.get(`common.documentTitle`)} ${
      title ? `- ${title}` : ''
    }`;
    this._title.setTitle(appTitle);
  }
}
