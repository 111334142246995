import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetComponent } from './widget.component';
import { LoadModuleModule } from '../../../directives/load-module/load-module.module';

@NgModule({
  imports: [CommonModule, LoadModuleModule],
  declarations: [WidgetComponent],
  exports: [WidgetComponent],
})
export class WidgetModule {}
