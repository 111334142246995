import { PlanActions, PlanActionTypes } from '../actions/plan.action';

export function planReducer(state = {}, action: PlanActionTypes) {
  switch (action.type) {
    case PlanActions.ADD_AVALIABLE_PLANS:
      return { ...state, ...action.payload };
    case PlanActions.ADD_SELECTED_PLAN:
      return { ...state, ...action.payload };
    case PlanActions.ADD_APPROVED_POLICY:
      return { ...state, ...action.payload };
    case PlanActions.ADD_AVALIABLE_ADDONS:
      return { ...state, ...action.payload };
    case PlanActions.ADD_SELECTED_ADDONS:
      return { ...state, ...action.payload };
    case PlanActions.ADD_PLAN_STATE:
      return { ...state, ...action.payload };
    case PlanActions.ADD_AVALIABLE_BUILDER:
      return { ...state, ...action.payload };
    case PlanActions.ADD_SELECTED_BUILDER:
      return { ...state, ...action.payload };
    case PlanActions.ADD_AVALIABLE_COVERAGE:
      return { ...state, ...action.payload };
    case PlanActions.ADD_SELECTED_COVERAGE:
      return { ...state, ...action.payload };
    case PlanActions.ADD_TOTAL_PREMIUM:
      return { ...state, ...{ totalPremium: action.payload } };
    case PlanActions.ADD_ADDITIONAL_CHARGE:
      return { ...state, ...{ additionalCharge: action.payload } };
    case PlanActions.ADD_TOTAL_ADDITIONAL_CHARGE:
      return { ...state, ...{ totalAdditionalCharge: action.payload } };
    case PlanActions.ADD_AVALIABLE_SME_BUILDER:
      return { ...state, ...action.payload };
    case PlanActions.ADD_SELECTED_SME_BUILDER:
      return { ...state, ...action.payload };
    case PlanActions.ADD__PAYMENT_FREQUENCY:
      return { ...state, ...{ paymentFrequency: action.payload } };
    case PlanActions.ADD_PRODUCT_CONFIG_ID:
      return { ...state, ...{ productConfigIds: action.payload } };
    case PlanActions.UPDATE_PRODUCT_CONFIG_ID:
      return { ...state, ...{ productConfigIds: action.payload } };
    default:
      return state;
  }
}
