import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerDialogComponent } from './pdf-viewer-dialog.component';
import { PdfViewerDialogService } from './pdf-viewer-dialog.service';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [CommonModule, PdfViewerModule, MatDialogModule],
  declarations: [PdfViewerDialogComponent],
  exports: [PdfViewerDialogComponent],
  providers: [PdfViewerDialogService],
  entryComponents: [PdfViewerDialogComponent],
  bootstrap: [PdfViewerDialogComponent],
})
export class PdfViewerDialogModule {}
