import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { BaseUrlService } from './base-url.service';
import { Store } from '@ngrx/store';
import { StudioState } from '../state/intial-state';
import { AuthGuardStatusChange } from '../state/actions/auth-guard.action';

@Injectable()
export class AuthGuardService implements CanActivate {
  private isRouteAuthenticated = false;

  constructor(
    private router: Router,
    private _bus: BaseUrlService,
    private _store: Store<StudioState>
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isRouteAuthenticated) {
      return true;
    }

    this.router.navigate([this._bus.baseUri], {
      queryParamsHandling: 'preserve',
    });
    return false;
  }

  isAuthenticated(val?: boolean) {
    if (val != null) {
      this.isRouteAuthenticated = val;
      this._store.dispatch(
        new AuthGuardStatusChange(this.isRouteAuthenticated)
      );
    }
    if (this.isRouteAuthenticated) {
      return true;
    } else {
      return false;
    }
  }
}
