import {
  AppContextActions,
  AppContextActionsType,
} from '../actions/app-context.action';

export function appContextReducer(state = {}, action: AppContextActionsType) {
  switch (action.type) {
    case AppContextActions.LOAD_APP_CONTEXT:
      return state;
    case AppContextActions.LOAD_APP_CONTEXT_SUCCESS:
      // @ts-ignore
      return { ...state, ...action.payload };
    case AppContextActions.LOAD_APP_CONTEXT_FAILURE:
      return state;
    default:
      return state;
  }
}
