import { Action } from '@ngrx/store';
import { StepState } from '../../../../projects/studio-widgets/src/lib/widgets/stepper/stepper.model';

export enum PurchaseActions {
  LOAD_PURCHASE_STEPS_SUCCESS = '[PURCHASE] LOAD PURCHASE STEPS SUCCESS',
  LOAD_PURCHASE_STEPS_FAILUIRE = '[PURCHASE] LOAD PURCHASE STEPS FAILURE',
  CHANGE_PURCHASE_STEPS_LANGUAGE = '[PURCHASE] CHANGE PURCHASE STEPS LANGUAGE',
  CHANGE_DECLARATION_CHECKED = '[PURCHASE] CHANGE PURCHASE DECLARATION CHECKED',
  CHANGE_ADDITIONAL_DECLARATION_CHECKED = '[PURCHASE] CHANGE PURCHASE ADDITIONAL DECLARATION CHECKED',

  NAVIGATE_NEXT_STEP = '[PURCHASE] NAVIGATE NEXT STEP',
  NAVIGATE_PREVIOUS_STEP = '[PURCHASE] NAVIGATE PREVIOUS STEP',
  NAVIGATE_NEXT_STEP_SUCCESS = '[PURCHASE] NAVIGATE NEXT STEP SUCCESS',
  NAVIGATE_TO_STEP = '[PURCHASE] NAVIGATE TO STEP',
  NAVIGATE_TO_STEP_SUCCESS = '[PURCHASE] NAVIGATE TO STEP SUCCESS',
  NAVIGATE_TO_ROUTE = '[PURCHASE] NAVIGATE TO ROUTE',
  NAVIGATE_TO_ROUTE_SUCCESS = '[PURCHASE] NAVIGATE TO ROUTE SUCCESS',
  MAKE_PAYMENT = '[PURCHASE] MAKE PAYMENT',
  RESET_PAYMENT = '[PURCHASE] RESET PAYMENT',
  RESET_MAKE_PAYMENT = '[PURCHASE] RESET MAKE PAYMENT',
  HANDLE_PURCHASE_ERROR = '[PURCHASE] HANDLE PURCHASE ERROR',
  HANDLE_PURCHASE_ERROR_SUCCESS = '[PURCHASE] HANDLE PURCHASE ERROR SUCCESS',
}

export class LoadPurchaseStepsSuccess implements Action {
  readonly type: string = PurchaseActions.LOAD_PURCHASE_STEPS_SUCCESS;

  constructor(public payload: Partial<StepState>) {}
}

export class ChangePurchaseStepsLanguage implements Action {
  readonly type: string = PurchaseActions.CHANGE_PURCHASE_STEPS_LANGUAGE;

  constructor(public payload: Partial<StepState>) {}
}

export class ChangeDeclarationChecked implements Action {
  readonly type: string = PurchaseActions.CHANGE_DECLARATION_CHECKED;

  constructor(public payload: boolean) {}
}

export class ChangeAdditionalDeclarationChecked implements Action {
  readonly type: string = PurchaseActions.CHANGE_ADDITIONAL_DECLARATION_CHECKED;

  constructor(public payload: any) {}
}

export class NavigateNextStep implements Action {
  readonly type: string = PurchaseActions.NAVIGATE_NEXT_STEP;

  constructor(public payload: Partial<StepState>) {}
}

export class NavigatePreviousStep implements Action {
  readonly type: string = PurchaseActions.NAVIGATE_PREVIOUS_STEP;

  constructor(public payload: Partial<StepState>) {}
}

export class NavigateToStep implements Action {
  readonly type: string = PurchaseActions.NAVIGATE_TO_STEP;

  constructor(public payload: Partial<StepState>) {}
}

export class NavigateToRoute implements Action {
  readonly type: string = PurchaseActions.NAVIGATE_TO_ROUTE;

  constructor(public payload: Partial<StepState>) {}
}

export class LoadPurchaseStepsFailure implements Action {
  readonly type: string = PurchaseActions.LOAD_PURCHASE_STEPS_FAILUIRE;

  constructor(public payload: { errors: [] }) {}
}

export class MakePayment implements Action {
  readonly type: string = PurchaseActions.MAKE_PAYMENT;

  constructor() {}
}

export class ResetPayment implements Action {
  readonly type: string = PurchaseActions.RESET_PAYMENT;

  constructor() {}
}

export class ResetMakePayment implements Action {
  readonly type: string = PurchaseActions.RESET_MAKE_PAYMENT;

  constructor() {}
}

export class HandlePurchaseError implements Action {
  readonly type: string = PurchaseActions.HANDLE_PURCHASE_ERROR;

  constructor(public payload?: string) {}
}

export class HandlePurchaseErrorSuccess implements Action {
  readonly type: string = PurchaseActions.HANDLE_PURCHASE_ERROR_SUCCESS;

  constructor(public payload: string) {}
}

export type PurchaseActionTypes =
  | LoadPurchaseStepsSuccess
  | LoadPurchaseStepsFailure
  | ChangePurchaseStepsLanguage
  | ChangeDeclarationChecked
  | NavigateNextStep
  | NavigatePreviousStep
  | NavigateToStep
  | NavigateToRoute
  | MakePayment
  | ResetMakePayment
  | HandlePurchaseError
  | HandlePurchaseErrorSuccess;
