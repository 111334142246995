import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { BaseUrlService } from './base-url.service';
import { AppContextService } from '../app.context.service';
import _camelCase from 'lodash-es/camelCase';
import { environment } from '../../environments/environment';
import { AuthGuardService } from './auth-guard.service';

@Injectable()
export class PageGuardService implements CanActivate {
  constructor(
    private _router: Router,
    private _appContextService: AppContextService,
    private _bus: BaseUrlService,
    private authService: AuthGuardService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const pageKey = _camelCase(route.url[0].path);

    if (this._appContextService.get(`pages.${pageKey}`) === undefined) {
      this._router.navigate([
        `${this._bus.baseUri}/${environment.uri.notFound}`,
      ]);
    } else if (
      this._appContextService.get(`pages.${pageKey}.authGuard`) === 'enabled' &&
      !this.authService.isAuthenticated()
    ) {
      this._router.navigate([this._bus.baseUri], {
        queryParamsHandling: 'preserve',
      });
    }
    return true;
  }
}
