import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LanguageSelectorComponent } from './language-selector.component';
import { NavigationButtonModule } from '../../../../../projects/studio-widgets/src/lib/shared/navigation-button/navigation-button.module';
import { LayoutModule } from '../../../layout/layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    NavigationButtonModule,
    LayoutModule,
    MatDialogModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [LanguageSelectorComponent],
  exports: [LanguageSelectorComponent],
  entryComponents: [LanguageSelectorComponent],
})
export class LanguageSelectorModule {}
