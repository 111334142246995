import { StateActionTypes, StateActions } from '../actions/state.action';

export function stateReducer(state = {}, action: StateActionTypes) {
  switch (action.type) {
    case StateActions.SET_STATE_ID:
      return { ...state, ...{ stateId: action.payload } };
    default:
      return state;
  }
}
