import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isEmpty } from 'lodash-es';

@Injectable()
export class FontService {
  private renderer: Renderer2;
  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  handleFont(language?: string) {
    if (isEmpty(language)) {
      if (window.location.href) {
        language = window.location.href
          .toLowerCase()
          .split('/')[7]
          .toLowerCase();
      }
    }

    switch (language) {
      case 'vn':
        this.renderer.addClass(this.document.body, 'chubb-vn');
        break;
      default:
        this.renderer.removeClass(this.document.body, 'chubb-vn');
        break;
    }
  }
}
