import { Action } from '@ngrx/store';

export enum DeviceDetailsActions {
  ADD_SELECTED_PHONE_MAKE_AND_MODEL = '[PHONE SELECT] ADD SELECTED PHONE MAKE AND MODEL',
  ADD_SELECTED_PHONE_IMEI = '[PHONE SELECT] ADD SELECTED PHONE IMEI',
  CLEAR_SELECTED_PHONE_DETAILS = '[PHONE SELECT] CLEAR SELECTED PHONE DETAILS',
  ADD_DEVICE_DETAILS_TO_SESSION_STORAGE = '[PLAN] ADD DEVICE DETAILS TO SESSION STORAGE',
  SHOW_MORE_MODELS = '[PHONE SELECT] SHOW/HIDE MORE MODELS',
}

export class AddSelectedPhoneMakeAndModel implements Action {
  readonly type: string =
    DeviceDetailsActions.ADD_SELECTED_PHONE_MAKE_AND_MODEL;

  constructor(public payload: { [key: string]: any }) {}
}

export class AddSelectedPhoneImei implements Action {
  readonly type: string = DeviceDetailsActions.ADD_SELECTED_PHONE_IMEI;

  constructor(public payload: { [key: string]: any }) {}
}

export class ShowMoreModels implements Action {
  readonly type: string = DeviceDetailsActions.SHOW_MORE_MODELS;

  constructor(public payload: { [key: string]: any }) {}
}

export class ClearSelectedPhoneDetails implements Action {
  readonly type: string = DeviceDetailsActions.CLEAR_SELECTED_PHONE_DETAILS;

  constructor() {}
}

export type DeviceDetailsActionTypes = AddSelectedPhoneMakeAndModel;
