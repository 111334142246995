import { Action } from '@ngrx/store';

export enum LanguageConfigActions {
  LANGUAGE_CONFIG_SUCCESS = '[LANGUAGE] LANGUAGE CONFIG SUCCESS',
  LANGUAGE_CONFIG_FAILURE = '[LANGUAGE]  LANGUAGE CONFIG FAILURE',
  LANGUAGE_POPUP_LOAD_SUCCESS = '[LANGUAGE] LANGUAGE POPUP LOAD SUCCESS',
  LANGUAGE_POPUP_CLOSE_SUCCESS = '[LANGUAGE] LANGUAGE POPUP CLOSE SUCCESS',
  LANGUAGE_CHANGE = '[LANGUAGE] LANGUAGE CHANGE',
  LANGUAGE_CHANGE_SUCCESS = '[LANGUAGE] LANGUAGE CHANGE SUCCESS',
}

export class LoadLanguageConfigSuccess implements Action {
  readonly type: string = LanguageConfigActions.LANGUAGE_CONFIG_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadLanguageConfigFailure implements Action {
  readonly type: string = LanguageConfigActions.LANGUAGE_CONFIG_FAILURE;

  constructor(public payload: any) {}
}

export class LanguagePopupLoadSuccess implements Action {
  readonly type: string = LanguageConfigActions.LANGUAGE_POPUP_LOAD_SUCCESS;

  constructor(public payload: any) {}
}

export class LanguagePopupCloseSuccess implements Action {
  readonly type: string = LanguageConfigActions.LANGUAGE_POPUP_CLOSE_SUCCESS;

  constructor(public payload: any) {}
}

export class LanguageChange implements Action {
  readonly type: string = LanguageConfigActions.LANGUAGE_CHANGE;

  constructor(public payload: string) {}
}

export class LanguageChangeSuccess implements Action {
  readonly type: string = LanguageConfigActions.LANGUAGE_CHANGE_SUCCESS;

  constructor(public payload: any) {}
}

export type LanguageConfigActionTypes =
  | LoadLanguageConfigSuccess
  | LoadLanguageConfigFailure
  | LanguagePopupLoadSuccess
  | LanguageChange
  | LanguageChangeSuccess;
