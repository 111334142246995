import {
  QuoteDetailsActionTypes,
  QuoteDetailsActions,
} from '../actions/quote-details.action';
import { format } from 'date-fns';

export function quoteDetailsReducer(
  state = {},
  action: QuoteDetailsActionTypes
) {
  switch (action.type) {
    case QuoteDetailsActions.ADD_QUOTE_DETAILS:
      for (const [key, value] of Object.entries(action.payload)) {
        if (value && (value._isAMomentObject || value instanceof Date)) {
          action.payload[key] = format(value, 'YYYY-MM-DDTHH:mm:ss.SSS');
        }
      }
      return {
        ...action.payload,
        ...{ selectedDisclaimer: state['selectedDisclaimer'] || false },
      };
    case QuoteDetailsActions.SELECTED_QUOTE_DISCLAIMER:
      return { ...state, selectedDisclaimer: true };
    default:
      return state;
  }
}
