import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class STUDIO {
  public base_href: string;
  public get country() {
    return this.base_href.split('/')[1];
  }
  public get language() {
    return this.base_href.split('/')[5];
  }
  constructor(private _router: Router) {
    const numberOfParams = 6;
    const pathName = window.location.pathname.split('/');
    this.base_href =
      '/' +
      (pathName.length >= numberOfParams
        ? pathName.slice(1, numberOfParams).join('/')
        : '');
  }
}
