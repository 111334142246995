import {
  Component,
  Input,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Loading } from './loading.model';
import { Subscription } from 'rxjs';
import { LoadingService, LoaderState } from './loading.service';

@Component({
  selector: 'studio-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadingComponent implements OnInit, OnDestroy {
  title: string;
  message: string;
  mode: 'fullscreen' | 'container' = 'fullscreen';
  show = false;

  private subscription: Subscription;

  constructor(private _loaderService: LoadingService) {}

  ngOnInit() {
    this.subscription = this._loaderService.loaderState.subscribe(
      (state: LoaderState) => {
        this.show = state.show;
        this.title = state.title;
        this.message = state.message;
        this.mode = state.mode;
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
