import {
  AuthGuardActionsType,
  AuthGuardActions,
} from '../actions/auth-guard.action';

export function authGuardReducer(state = {}, action: AuthGuardActionsType) {
  switch (action.type) {
    case AuthGuardActions.AUTH_GUARD_STATUS_CHANGE:
      return action.payload;
    default:
      return state;
  }
}
