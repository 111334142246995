import {
  Component,
  ViewEncapsulation,
  Input,
  Inject,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Partner } from '../../../models/partner';
import { Router } from '@angular/router';
import { STUDIO } from '../../../const/studio-base-href';
import { AppService } from '../../../services/app.service';

@Component({
  selector: 'studio-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
  private _baseHref = this._studio.base_href;
  public year = `© ${new Date().getFullYear()} `;
  public isChubbBranded = this._appService.isChubbBranded();
  @Input() partner$: Observable<Partner>;
  @Input() contactLinks: any[];
  @Input() footerText: any[];
  @Input() socialLinks: any[];
  @Input() links: any[];
  @Input() footerLanguage: any;
  @Output() languageChanged = new EventEmitter();

  linkActions(contactLink) {
    switch (contactLink.type) {
      case 'route':
        this.router.navigate([this._baseHref + contactLink.url]);
        break;
      case 'callback':
        (<any>window).location.href = contactLink.url;
        break;
      case 'url':
        (<any>window).open(contactLink.url, '_blank');
        break;
      default:
        break;
    }
  }

  changeLanguage(selectedLanguage) {
    this.languageChanged.emit(selectedLanguage);
  }

  constructor(
    private router: Router,
    private _studio: STUDIO,
    private _appService: AppService
  ) {}

  ngOnInit(): void {
    const style = document.createElement('style');
    const css = `.footer-chubb-icon--underwritten { background-image: url('/assets/logos/footer_underwritten_by_chubb_${
      this._studio.country
    }_${this._studio.language}.svg'); }`;
    style.innerHTML = css;
    document.head.appendChild(style);
  }
}
