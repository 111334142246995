import { Action } from '@ngrx/store';

export enum AuthGuardActions {
  AUTH_GUARD_STATUS_CHANGE = '[AUTH GUARD] AUTH GUARD STATUS CHANGE',
  AUTH_GUARD_STATUS_CHANGE_SUCCESS = '[AUTH GUARD] AUTH GUARD STATUS CHANGE SUCCESS',
}

export class AuthGuardStatusChange implements Action {
  readonly type: string = AuthGuardActions.AUTH_GUARD_STATUS_CHANGE;
  constructor(public payload: boolean) {}
}

export class AuthGuardStatusChangeSuccess implements Action {
  readonly type: string = AuthGuardActions.AUTH_GUARD_STATUS_CHANGE_SUCCESS;
  constructor() {}
}

export type AuthGuardActionsType = AuthGuardStatusChange;
