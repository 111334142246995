import { Action } from '@ngrx/store';
import { Partner } from '../../models/partner';

export enum PartnerConfigActions {
  PARTNER_CONFIG_SUCCESS = '[PARTNER] PARTNER CONFIG SUCCESS',
  PARTNER_CONFIG_FAILURE = '[PARTNER]  PARTNER CONFIG FAILURE',
  PARTNER_STYLE_LOAD_SUCCESS = '[PARTNER] PARTNER STYLE LOAD SUCCESS',
}

export class LoadPartnerConfigSuccess implements Action {
  readonly type: string = PartnerConfigActions.PARTNER_CONFIG_SUCCESS;

  constructor(public payload: Partner) {}
}

export class LoadPartnerConfigFailure implements Action {
  readonly type: string = PartnerConfigActions.PARTNER_CONFIG_FAILURE;

  constructor(public payload: Partner) {}
}

export class PartnerStyleLoadSuccess implements Action {
  readonly type: string = PartnerConfigActions.PARTNER_STYLE_LOAD_SUCCESS;

  constructor(public payload: Partner) {}
}

export type PartnerConfigActionTypes =
  | LoadPartnerConfigSuccess
  | LoadPartnerConfigFailure
  | PartnerStyleLoadSuccess;
