import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { take, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { StudioState } from '../state/intial-state';
import { AddTotalPremium } from '../state/actions/plan.action';
import { isEmpty } from 'lodash';
import { AppContextService } from '../app.context.service';
import { CommonUtils } from '../utils/common.utils';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { EMPTY } from 'rxjs';
import { LoadingService } from '../components/shared/loading/loading.service';

@Injectable()
export class S6PriceInfoResolver implements Resolve<any> {
  constructor(
    private _store: Store<StudioState>,
    private _appContext: AppContextService,
    private _httpClient: HttpClient,
    private _loaderService: LoadingService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const appContext = this._appContext.get(
      `pages.${route.routeConfig.path}.content`
    );

    if (appContext && !appContext.disableS6PriceCheck) {
      const salesStoreData = appContext.salesStoreData;

      if (salesStoreData && salesStoreData.length > 0) {
        this._loaderService.open(this._appContext.get('common.loader.title'));
        let currentTotalPremium = 0;
        let salesObject = {};

        this._store.pipe(take(1)).subscribe((store: any) => {
          salesStoreData.forEach((key: string) => {
            salesObject = Object.assign(salesObject, {
              [key.split('.').pop()]: CommonUtils.getPropertyByKeyPath(
                store,
                key
              ),
            });
          });
          currentTotalPremium = store.plan.totalPremium;
        });

        return this._httpClient
          .post(environment.apiUrl.s6_price_info, salesObject)
          .toPromise()
          .catch(() => {
            this._loaderService.close();
            return EMPTY;
          })
          .then((res: any) => {
            if (
              !isEmpty(res) &&
              res.status &&
              res.status.statusCode === '00' &&
              res.priceDetails &&
              res.priceDetails.Incl &&
              Math.abs(res.priceDetails.Incl - currentTotalPremium) < 1
            ) {
              this._store.dispatch(new AddTotalPremium(res.priceDetails.Incl));
            }
            this._loaderService.close();
          });
      }
    }
  }
}
