import {
  Component,
  OnInit,
  AfterViewChecked,
  ChangeDetectorRef,
  OnDestroy,
  Inject,
} from '@angular/core';
import { FontService } from './services/font.service';
@Component({
  selector: 'studio-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  constructor(
    private cdr: ChangeDetectorRef,
    private _fontService: FontService
  ) {}

  ngOnInit() {
    this._fontService.handleFont();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  ngOnDestroy(): void {}
}
