import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ScrollToTopComponent } from './scroll-to-top.component';
import { NavigationButtonModule } from '../../../../../projects/studio-widgets/src/lib/shared/navigation-button/navigation-button.module';
import { LayoutModule } from '../../../layout/layout.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    NavigationButtonModule,
    LayoutModule,
  ],
  declarations: [ScrollToTopComponent],
  exports: [ScrollToTopComponent],
})
export class ScrollToTopModule {}
