import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppContextService } from '../app.context.service';
import { catchError, map, tap } from 'rxjs/operators';
import {
  LoadingIndicatorHideAction,
  LoadingIndicatorShowAction,
  LoadPartnerConfigSuccess,
} from '@studio/state/actions';
import { StudioState } from '@studio/state/intial-state';
import { Store } from '@ngrx/store';
import { StudioError } from '@studio/error-handlers/studio.error';
import { StudioErrorHandler } from '@studio/error-handlers/studio.error-handler';
import { environment } from '../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { WINDOW } from '@studio/services/window.service';
import { DomainsMappingService } from '@studio/services/domains-mapping.service';
import { BaseUrlService } from '@studio/services/base-url.service';
import { Partner } from '@studio/models/partner';
import { DOCUMENT } from '@angular/common';
import { TenantLoadSuccess } from '@studio/state/actions/tenant.action';
import { isEmpty } from 'lodash-es';
import { LoadLanguageConfigSuccess } from '../state/actions/language.action';

declare function cssVars(): any;

@Injectable()
export class AppContextGuard implements CanActivate {
  private renderer: Renderer2;

  constructor(
    private _appContext: AppContextService,
    private _store: Store<StudioState>,
    private _errorHandler: StudioErrorHandler,
    private _router: Router,
    private _route: ActivatedRoute,
    private _domainMappingService: DomainsMappingService,
    private _bus: BaseUrlService,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (this._appContext.get('')) {
      return of(true);
    }

    this._store.dispatch(
      new LoadingIndicatorShowAction({
        loading: {
          title: null,
        },
        key: 'loadingAppContext',
      })
    );

    let configPath = null;

    try {
      configPath = this._getConfigPath(
        this._domainMappingService.getMapping(),
        state.url
      );
    } catch (e) {
      this._store.dispatch(new LoadingIndicatorHideAction('loadingAppContext'));
    }

    if (!configPath) {
      this._store.dispatch(new LoadingIndicatorHideAction('loadingAppContext'));
      return of(false);
    }

    return this._appContext.load(configPath).pipe(
      tap((appContext) => {
        this._store.dispatch(
          new LoadingIndicatorHideAction('loadingAppContext')
        );
      }),
      map((appContext) => {
        this._store.dispatch(
          new LoadPartnerConfigSuccess(<Partner>(
            this._appContext.get(`common.branding`)
          ))
        );

        this._store.dispatch(
          new TenantLoadSuccess({
            name: isEmpty(this._bus.baseUri) ? 'DEFAULT' : this._bus.baseUri,
          })
        );

        if (this._appContext.get(`common.language`)) {
          this._store.dispatch(
            new LoadLanguageConfigSuccess(
              this._appContext.get(`common.language`)
            )
          );
        }

        return !!appContext;
      }),
      catchError((error) => {
        this._store.dispatch(
          new LoadingIndicatorHideAction('loadingAppContext')
        );
        this._errorHandler.handleError(
          new StudioError(`Can't fetch Application Context.`, error)
        );

        if (error instanceof HttpErrorResponse) {
          if (error.status === 404) {
            this._router.navigate([environment.uri.notFound]);
          }
        }

        return of(false);
      })
    );
  }

  _getConfigPath(domainsMapping: any, stateUrl: string): string {
    const domainName = location.host;
    let domainConfig = domainsMapping[domainName];

    if (!domainConfig) {
      domainConfig = domainsMapping['*'];
      if (!domainConfig) {
        throw new StudioError(
          `Can't find configuration for domain ${domainName}`
        );
      }
    }

    const defaultPath = domainConfig.find((path) => path.default === true);
    if (!defaultPath) {
      throw new StudioError(`Can't find default path for domain ${domainName}`);
    }

    const locationPathBaseHref = stateUrl
      .split('/')
      .slice(0, 6)
      .join('/');

    if (defaultPath.baseHref === '*') {
      if (stateUrl.split('/').slice(0, 6).length >= 5) {
        this._bus.baseUri = locationPathBaseHref + '/';
        return locationPathBaseHref;
      } else {
        this._router.navigate([environment.uri.notFound]);
      }
    }

    if (locationPathBaseHref === defaultPath.baseHref) {
      const routePath = stateUrl
        .split('/')
        .slice(6, stateUrl.split('/').length)
        .join('/');

      this._bus.baseUri = '';

      this._store.dispatch(new LoadingIndicatorHideAction('loadingAppContext'));
      this._router.navigate([routePath]);
      return null;
    }

    if (stateUrl.split('/').slice(0, 6).length < 5) {
      this._bus.baseUri = '';
      return defaultPath.baseHref;
    }

    const baseHref = domainConfig.find(
      (path) => path.baseHref === locationPathBaseHref
    );

    if (!baseHref) {
      this._router.navigate([environment.uri.notFound]);
      throw new StudioError(`Can't find path ${locationPathBaseHref}`);
    }

    this._bus.baseUri = locationPathBaseHref + '/';

    return locationPathBaseHref;
  }
}
