import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, EMPTY, from } from 'rxjs';
import { catchError, take, mergeMap } from 'rxjs/operators';
import { AppContextService } from '../app.context.service';
import { AuthGuardService } from './auth-guard.service';
import { environment } from '../../environments/environment';
import { SalesResponse } from '../models/s6-sales-response';
import { DokuRequest } from '../models/doku';
import { Store } from '@ngrx/store';
import { StudioState } from '../state/intial-state';
import { CommonUtils } from '../utils/common.utils';
import { assign, cloneDeep, omit } from 'lodash';
import { BaseUrlService } from './base-url.service';

@Injectable()
export class AppService {
  private brandingType: any;

  constructor(
    private _appContext: AppContextService,
    private _authService: AuthGuardService,
    private _httpClient: HttpClient,
    private _router: Router,
    private _bus: BaseUrlService,
    private _store: Store<StudioState>
  ) {}

  // ********************* Site Configuration ********************* \\

  public isChubbBranded() {
    this.brandingType = this._appContext.get(`common.branding.type`);

    return (
      this.brandingType.indexOf !== undefined &&
      this.brandingType.indexOf('NON_CHUBB') === -1
    );
  }

  public isAgent() {
    this.brandingType = this._appContext.get(`common.branding.type`);

    return (
      this.brandingType.indexOf !== undefined &&
      this.brandingType.indexOf('NON_AGENT') === -1
    );
  }

  // ********************* Phone ********************* \\

  public getPhoneList(): Observable<any> {
    return this._httpClient.get(environment.apiUrl.phoneList);
  }

  public getImeiDetails(imei) {
    return this._httpClient.get(`${environment.apiUrl.imei}/${imei}`).pipe(
      catchError(() => {
        return EMPTY;
      })
    );
  }

  public confirmImei(data): Observable<any> {
    return this._httpClient.post(environment.apiUrl.confirmImei, data);
  }

  // ********************* SME Builder ********************* \\

  public getSmeBuilder(additionalData): Observable<any> {
    return this._httpClient
      .post(environment.apiUrl.smeBuilder, additionalData)
      .pipe(
        catchError((error) => {
          this._router.navigate([
            `${this._bus.baseUri}/${environment.uri.error}`,
          ]);
          return EMPTY;
        })
      );
  }

  // ********************* Builder ********************* \\

  public getBuilder(additionalData?): Observable<any> {
    return this._httpClient
      .post(environment.apiUrl.builder, additionalData)
      .pipe(
        catchError((error) => {
          this._router.navigate([
            `${this._bus.baseUri}/${environment.uri.error}`,
          ]);
          return EMPTY;
        })
      );
  }

  // ********************* Coverage ********************* \\

  public getCoverage(additionalData?): Observable<any> {
    return this._httpClient
      .post(environment.apiUrl.coverage, additionalData)
      .pipe(
        catchError((error) => {
          this._router.navigate([
            `${this._bus.baseUri}/${environment.uri.error}`,
          ]);
          return EMPTY;
        })
      );
  }

  // ********************* Plans and Benefits ********************* \\

  public getPlan(additionalData): Observable<any> {
    return this._httpClient.post(environment.apiUrl.plan, additionalData).pipe(
      catchError((error) => {
        this._router.navigate([
          `${this._bus.baseUri}/${environment.uri.error}`,
        ]);
        return EMPTY;
      })
    );
  }

  // ********************* Addons ********************* \\

  public getAddons(additionalData): Observable<any> {
    return this._httpClient
      .post(environment.apiUrl.addons, additionalData)
      .pipe(
        catchError((error) => {
          this._router.navigate([
            `${this._bus.baseUri}/${environment.uri.error}`,
          ]);
          return EMPTY;
        })
      );
  }

  public sendAnnualWages(totalWage) {
    return this._httpClient
      .post(environment.apiUrl.annual_wages, { totalAnnualWages: totalWage })
      .toPromise();
  }

  // ********************* Feedback ********************* \\

  public sendFeedback(feedback) {
    return this._httpClient
      .post(environment.apiUrl.feedback, feedback)
      .toPromise();
  }

  // ********************* Contact ********************* \\

  public sendContact(contact) {
    return this._httpClient
      .post(environment.apiUrl.contact, contact)
      .toPromise();
  }

  // ********************* Lead Gen ********************* \\

  public sendLeadGen(leadGenDetails) {
    return this._httpClient.post(environment.apiUrl.leadgen, leadGenDetails);
  }

  // ********************* Purchase ********************* \\

  public purchasePolicy(salesUrl, salesData): Observable<SalesResponse> {
    return this._httpClient.post<SalesResponse>(salesUrl, salesData);
  }

  public purchaseTotalPremiumCheck(data) {
    return this._httpClient.post(environment.apiUrl.sales_totalPremium, data);
  }

  public clearAppData() {
    this._authService.isAuthenticated(false);
  }

  // ********************* Doku ********************* \\

  public generateDokuHash(hashUrl, salesData): Observable<DokuRequest> {
    return this._httpClient.post<DokuRequest>(hashUrl, salesData);
  }

  public dokuPaymentCheck(paymentStatusUrl): Observable<SalesResponse> {
    return this._httpClient.get<SalesResponse>(paymentStatusUrl).pipe(
      catchError((x) => {
        return EMPTY;
      })
    );
  }

  public generateDokuRecurringFormData(salesData): Observable<any> {
    return this._httpClient.post(environment.apiUrl.doku_recurring, salesData);
  }

  // ********************* PayDollar ********************* \\

  public generatePayDollarData(salesData): Observable<any> {
    return this._httpClient.post(
      environment.apiUrl.paydollar_generate,
      salesData
    );
  }

  public payDollarPaymentSuccess(id, data): Observable<any> {
    return this._httpClient
      .post(`${environment.apiUrl.paydollar_success}/${id}`, data)
      .pipe(
        catchError((x) => {
          return EMPTY;
        })
      );
  }

  // ********************* China Payment ********************* \\

  public generateChinaPaymentHTML(salesData): Observable<any> {
    return this._httpClient.post(
      environment.apiUrl.chinapayment_generate,
      salesData,
      {
        responseType: 'text',
      }
    );
  }

  // ********************* OTP ********************* \\

  public sendOtp(data, isPost = false) {
    if (isPost) {
      return this._httpClient.post(`${environment.apiUrl.sendOtp}`, {
        data: data,
      });
    } else {
      return this._httpClient.get(`${environment.apiUrl.sendOtp}/${data}`);
    }
  }

  public verifyOtp(otp): any {
    return this._httpClient.post(environment.apiUrl.verifyOtp, otp).pipe(
      catchError((x) => {
        return EMPTY;
      })
    );
  }

  // ********************* Save Quote ********************* \\
  public saveQuoteNotify(data): Observable<any> {
    return from(
      this.setStateDetails().then((stateId) => {
        return this._httpClient
          .post(
            environment.apiUrl.saveQuote_notify,
            Object.assign(cloneDeep(data), {
              url: `${window.location.href}?stateId=${stateId}`,
            })
          )
          .toPromise();
      })
    );
  }

  // ********************* Unsubscription ********************* \\
  public unsubscription(data): Observable<any> {
    return this._httpClient.post(`${environment.apiUrl.unsubscription}`, data);
  }

  // ********************* Survey ********************* \\
  public survey(data): Observable<any> {
    return this._httpClient.post(`${environment.apiUrl.survey}`, data);
  }

  // ********************* SSI Quote ********************* \\
  public getSsiQuote(data): Observable<any> {
    return this._httpClient.post(`${environment.apiUrl.ssi_quote}`, data);
  }

  // ********************* State ********************* \\
  public setStateDetails(isForPayment = false) {
    return this._store
      .pipe(
        take(1),
        mergeMap((state) => {
          let currentState: StudioState;
          currentState = cloneDeep(state);
          if (isForPayment) {
            assign(currentState, { additionalData: { isForPayment: true } });
          }
          const omittedState = omit(currentState, [
            'prefetchData',
            'plan.availableSmeBuilder',
            'plan.availablePlans',
            'plan.avaliableAddons',
          ]);
          const stateObject = {
            content: CommonUtils.encryptData({
              route:
                this._router.url.indexOf('?') === -1
                  ? this._router.url
                  : this._router.url.substring(
                      0,
                      this._router.url.indexOf('?')
                    ),
              state: omittedState,
            }),
            isForPayment: isForPayment,
          };
          state = currentState;
          return this._httpClient
            .post(environment.apiUrl.state, stateObject)
            .toPromise()
            .then((x) => x['id']);
        })
      )
      .toPromise();
  }

  public getStateDetails(id): Observable<any> {
    return this._httpClient.get(`${environment.apiUrl.state}/${id}`);
  }
}
