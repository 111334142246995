import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PdfViewerDialogComponent } from './pdf-viewer-dialog.component';

@Injectable()
export class PdfViewerDialogService {
  constructor(private dialog: MatDialog) {}

  open(pdfSrc, linkCloseLabel) {
    this.dialog.open(PdfViewerDialogComponent, {
      data: {
        pdfSrc: pdfSrc,
        linkCloseLabel: linkCloseLabel,
      },
      position: {
        top: '10px',
        bottom: '10px',
      },
      height: '98%',
      width: '100vw',
      maxWidth: '95vw',
      panelClass: 'pdf-viewer-overlay',
    });
  }
}
