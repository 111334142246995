import {
  BottomCtaActionTypes,
  BottomCtaActions,
} from '../actions/bottom-cta.action';

export function bottomCtaReducer(state = {}, action: BottomCtaActionTypes) {
  switch (action.type) {
    case BottomCtaActions.SET_BOTTOM_CTA_STATE:
      return { ...state, ...action.payload };
    case BottomCtaActions.HIDE_BOTTOM_CTA_BUTTON:
      return {
        ...state,
        ...{ buttonIsHidden: true },
      };
    case BottomCtaActions.SHOW_BOTTOM_CTA_BUTTON:
      return {
        ...state,
        ...{ buttonIsHidden: false },
      };
    default:
      return state;
  }
}
