import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutComponent } from './layout.component';
import { FooterComponent } from '../components/shared/footer/footer.component';
import { SocialMediaComponent } from '../components/shared/social-media/social-media.component';
import { PartnerComponent } from '../components/shared/partner/partner.component';
import { PipeModule } from '../components/shared/pipes/pipes.module';
import { AppNavbarModule } from '../components';
import { MediaQueryService } from '@crux/services/util';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    AppNavbarModule,
    PipeModule,
  ],
  declarations: [
    LayoutComponent,
    FooterComponent,
    SocialMediaComponent,
    PartnerComponent,
  ],
  providers: [MediaQueryService],
  exports: [PartnerComponent],
})
export class LayoutModule {}
