import { Action } from '@ngrx/store';

export enum ScreenerDetailsActions {
  ADD_SCREENER_DETAILS = '[SCREENER] ADD SCREENER DETAILS',
  ADD_SCREENER_DETAILS_TO_SESSION_STORAGE = '[SCREENER] ADD SCREENER DETAILS TO SESSION STORAGE',
}

export class AddScreenerDetails implements Action {
  readonly type: string = ScreenerDetailsActions.ADD_SCREENER_DETAILS;

  constructor(public payload: { [key: string]: any }) {}
}

export type ScreenerDetailsActionTypes = AddScreenerDetails;
