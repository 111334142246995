import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Widget } from './widget.model';
import { LoadModuleContext } from '../../../directives/load-module/lazy-module.context';
import { StudioState } from '../../../state/intial-state';
import { Store } from '@ngrx/store';
import { AppContextService } from '../../../app.context.service';
import { WidgetOutputEvent } from '..//../../components/shared/widget/widget-output-event.model';

@Component({
  selector: 'studio-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WidgetComponent implements Widget {
  @Input() name: string;

  @Input() context: LoadModuleContext;

  @Output() ready = new EventEmitter<boolean>();

  @Output() widgetOutput = new EventEmitter<WidgetOutputEvent>();

  _store: Store<StudioState>;

  /**
   * @todo to be used when using load-module directive
   * from CRUX Components
   */
  moduleRegistry: {
    [key: string]: string;
  };

  constructor(
    private store: Store<StudioState>,
    private _appContext: AppContextService
  ) {
    this._store = store;
    this.moduleRegistry = this._appContext.get(`moduleRegistry`);
  }

  /**
   * Emits readiness status of widget
   * @param {boolean} isReady - readiness status of widget
   */
  widgetIsReady(isReady: boolean): void {
    this.ready.emit(isReady);
  }

  onWidgetOutput(output: WidgetOutputEvent): void {
    this.widgetOutput.emit(output);
  }
}
