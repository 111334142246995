import { Injectable } from '@angular/core';
import * as studioDomains from '../../../studio.domains.json';

@Injectable({
  providedIn: 'root',
})
export class DomainsMappingService {
  constructor() {}

  getMapping(): any {
    return studioDomains;
  }
}
