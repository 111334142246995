import { Action } from '@ngrx/store';

export enum ApplicantDetailsActions {
  ADD_APPLICANT_DETAILS = '[APPLICANT] ADD APPLICANT DETAILS',
  ADD_NEW_KEY_TO_APPLICANT_DETAILS = '[PLAN] ADD NEW KEY TO APPLICANT DETAILS',
}

export class AddApplicantDetails implements Action {
  readonly type: string = ApplicantDetailsActions.ADD_APPLICANT_DETAILS;

  constructor(public payload: { [key: string]: any }) {}
}

export class AddNewKeyToApplicantDetails implements Action {
  readonly type: string =
    ApplicantDetailsActions.ADD_NEW_KEY_TO_APPLICANT_DETAILS;

  constructor(public payload: { [key: string]: any }) {}
}

export type ApplicantDetailsActionTypes =
  | AddApplicantDetails
  | AddNewKeyToApplicantDetails;
