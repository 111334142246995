import { Action } from '@ngrx/store';

export enum BottomCtaActions {
  SET_BOTTOM_CTA_STATE = '[BOTTOM CTA] SET BOTTOM CTA STATE',
  HIDE_BOTTOM_CTA_BUTTON = '[BOTTOM CTA] HIDE BOTTOM CTA BUTTON',
  SHOW_BOTTOM_CTA_BUTTON = '[BOTTOM CTA] SHOW BOTTOM CTA BUTTON',
}

export class SetBottomCtaState implements Action {
  readonly type: string = BottomCtaActions.SET_BOTTOM_CTA_STATE;

  constructor(public payload: { [key: string]: any }) {}
}

export class HideBottomCtaButton implements Action {
  readonly type: string = BottomCtaActions.HIDE_BOTTOM_CTA_BUTTON;

  constructor() {}
}

export class ShowBottomCtaButton implements Action {
  readonly type: string = BottomCtaActions.SHOW_BOTTOM_CTA_BUTTON;

  constructor() {}
}

export type BottomCtaActionTypes = SetBottomCtaState;
