import {
  PurchaseActions,
  PurchaseActionTypes,
} from '../actions/purchase.action';
import _findIndex from 'lodash-es/findIndex';
import _map from 'lodash-es/map';
import { pick, map, partialRight, merge, cloneDeep } from 'lodash-es';

export function purchaseReducer(
  state = { stepsState: [] },
  action: PurchaseActionTypes
) {
  switch (action.type) {
    /**
     * Load purchase steps.
     * Sets the initial state of stepper steps
     */
    case PurchaseActions.LOAD_PURCHASE_STEPS_SUCCESS:
      // @ts-ignore
      return { stepsState: [...action.payload] };
    /**
     * Action to be used when stepper step fails to load
     */
    case PurchaseActions.LOAD_PURCHASE_STEPS_FAILUIRE:
      // @ts-ignore
      return { stepsState: [...action.payload] };

    case PurchaseActions.CHANGE_PURCHASE_STEPS_LANGUAGE:
      if (state.stepsState.length === 0) {
        return state;
      }
      return {
        stepsState: merge(
          cloneDeep(state.stepsState),
          // @ts-ignore
          map(action.payload, partialRight(pick, ['id', 'label']))
        ),
      };
    case PurchaseActions.CHANGE_ADDITIONAL_DECLARATION_CHECKED:
      // @ts-ignore
      return { ...state, ...{ additionalDeclaration: action.payload } };
    case PurchaseActions.CHANGE_DECLARATION_CHECKED:
      // @ts-ignore
      return { ...state, ...{ isDeclarationChecked: action.payload } };
    /**
     * Navigate to any given step in the stepper
     */
    // @todo refactor to smaller functions
    case PurchaseActions.NAVIGATE_TO_STEP:
      const navigateSteps = cloneDeep(state.stepsState);

      const navigateStepsIndex = _findIndex(navigateSteps, (step) => {
        // @ts-ignore
        return step.id === action.payload.id;
      });
      navigateSteps[navigateStepsIndex].active = true;
      navigateSteps[navigateStepsIndex].completed = false;

      const stepsAfter = navigateSteps.slice(
        navigateStepsIndex + 1,
        navigateSteps.length
      );
      const updatedAfterSteps = _map(stepsAfter, (navigateStep) => {
        // @ts-ignore
        navigateStep.active = false;
        navigateStep.completed = false;
        return navigateStep;
      });

      const stepsBefore = navigateSteps.slice(0, navigateStepsIndex);
      const updatedBeforeSteps = _map(stepsBefore, (navigateStep) => {
        // @ts-ignore
        navigateStep.active = false;
        navigateStep.completed = true;
        return navigateStep;
      });

      return {
        ...state,
        stepsState: [
          ...updatedBeforeSteps,
          navigateSteps[navigateStepsIndex],
          ...updatedAfterSteps,
        ],
      };
    /**
     * Navigate to the next step in the given stepper steps
     */
    // @todo refactor to smaller functions
    case PurchaseActions.NAVIGATE_NEXT_STEP:
      const steps = cloneDeep(state.stepsState);
      const stepIndex = _findIndex(steps, (step) => {
        // @ts-ignore
        return step.id === action.payload.id;
      });
      steps[stepIndex - 1].active = false;
      steps[stepIndex - 1].completed = true;
      steps[stepIndex - 1].interacted = true;
      steps[stepIndex] = {
        ...steps[stepIndex],
        // @ts-ignore
        ...action.payload,
      };
      return { ...state, stepsState: [...steps] };
    /**
     * Resets the payment trigger to false
     */
    case PurchaseActions.RESET_MAKE_PAYMENT:
      return { ...state, makePayment: false, resetPayment: false };
    /**
     * Triggers the payment submission
     */
    case PurchaseActions.MAKE_PAYMENT:
      return { ...state, makePayment: true, resetPayment: false };
    case PurchaseActions.RESET_PAYMENT:
      return { ...state, makePayment: false, resetPayment: true };
    default:
      return state;
  }
}
