import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from './dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  open(
    title: string,
    messages: string[],
    buttonLabel: string,
    illustration?: string,
    panelClass?: string
  ) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxWidth = illustration ? '750px' : '95vw';
    dialogConfig.minWidth = '40vw';
    dialogConfig.panelClass = panelClass
      ? ['studio-dialog', panelClass]
      : 'studio-dialog';
    dialogConfig.data = {
      title,
      message: messages,
      buttonLabel,
      illustration,
    };
    return this.dialog.open(DialogComponent, dialogConfig);
  }

  openConfirmation(
    title: string,
    messages: string[],
    buttonLabel: string,
    cancelButtonLabel: string,
    panelClass?: string
  ) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxWidth = '500px';
    dialogConfig.minWidth = '40vw';
    dialogConfig.panelClass = panelClass
      ? ['studio-dialog', panelClass]
      : 'studio-dialog';
    dialogConfig.data = {
      title,
      message: messages,
      buttonLabel,
      cancelButtonLabel,
      type: 'confirmation',
    };
    dialogConfig.disableClose = true;
    return this.dialog.open(DialogComponent, dialogConfig);
  }
}
