import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { BaseUrlService } from './base-url.service';
import { AppContextService } from '../app.context.service';
import { environment } from '../../environments/environment';
import { isBefore, isAfter } from 'date-fns';

@Injectable()
export class ExpiryGuardService implements CanActivate {
  constructor(
    private _router: Router,
    private _appContextService: AppContextService,
    private _bus: BaseUrlService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const expiry = this._appContextService.get(`expiry`);
    if (expiry !== undefined) {
      if (expiry.startDate) {
        const startDate = new Date(expiry.startDate);
        if (this.isValidDate(startDate)) {
          if (isBefore(new Date(), startDate)) {
            this._router.navigate([
              `${this._bus.baseUri}/${environment.uri.expired}`,
            ]);
          }
        }
      }
      if (expiry.endDate) {
        const endDate = new Date(expiry.endDate);
        if (this.isValidDate(endDate)) {
          if (isAfter(new Date(), endDate)) {
            this._router.navigate([
              `${this._bus.baseUri}/${environment.uri.expired}`,
            ]);
          }
        }
      }
    }
    return true;
  }

  isValidDate(date) {
    return date instanceof Date && !isNaN(date.getTime());
  }
}
