import { NgModule } from '@angular/core';

import { CurrencyPipe } from './currencyPipe';
import { SafeUrlPipe, SafeHTMLPipe } from './safe.pipe';

export const Pipes = [CurrencyPipe, SafeUrlPipe, SafeHTMLPipe];

@NgModule({
  declarations: [Pipes],
  exports: [Pipes],
})
export class PipeModule {}
