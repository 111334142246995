// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  blobUrl: 'https://chubbstudiosalesuat.blob.core.windows.net/data',
  siteKey: '6Ldof44UAAAAALrwahkqrvYPSJGg3asIkybV4gRD',
  uri: {
    home: 'home',
    viettelOtp: 'viettel-otp',
    otp: 'otp',
    phoneSelect: 'phone-select',
    builder: 'builder',
    smeBuilder: 'sme-builder',
    quote: 'quote',
    screener: 'screener',
    coverage: 'coverage',
    plan: 'plan',
    leadgen: 'leadgen',
    addons: 'addons',
    applicantDetails: 'details',
    review: 'review',
    payment: 'payment',
    dokuPayment: 'doku-payment',
    dokuRecurring: 'doku-recurring',
    checkout: 'checkout',
    paydollar: 'paydollar',
    chinaPayment: 'china-payment',
    success: 'success',
    imei: 'imei',
    help: 'help',
    contactUs: 'contact-us',
    error: 'error',
    notFound: '404',
    maintenance: 'maintenance',
    expired: 'expired',
    retrieveQuote: 'retrieve-quote',
    unsubscribe: 'unsubscribe',
    survey: 'survey',
  },
  apiUrl: {
    phoneList: '/api/phone/list',
    imei: '/api/phone/imei',
    confirmImei: '/api/phone/imei/confirmation',
    plan: '/api/plans',
    addons: '/api/addons',
    builder: '/api/builder',
    smeBuilder: '/api/sme/builder',
    coverage: '/api/coverage',
    feedback: '/api/feedback',
    leadgen: '/api/leadgen',
    doku_recurring: '/api/doku/recurring/generate',
    paydollar_generate: '/api/paydollar/generate',
    paydollar_success: '/api/paydollar/payment/success',
    chinapayment_generate: '/api/chinapayment/generate',
    sendOtp: '/api/otp/send',
    verifyOtp: '/api/otp/verify',
    state: '/api/state',
    ssi_quote: '/api/ssi/quote',
    contact: '/api/contact',
    saveQuote_notify: '/api/savequote/notify',
    s6_price_info: '/api/sales/s6priceinfo',
    sales_totalPremium: '/api/sales/TotalPremium',
    annual_wages: '/api/sme/calcworkcomp',
    unsubscription: '/api/studio/unsubscription',
    survey: '/api/studio/survey',
  },
  /**
   * Analytics
   */
  gtmTrackingId: 'GTM-K9MSK32',
  gtmAuth: 'dXZVxs_VuRM1DgKQvxyjmQ',
  gtmPreview: 'env-84',
  appInsightsInstrumentationKey: 'a1a7f01b-19e2-4c6f-ac37-5bd8556c9a51',
};
