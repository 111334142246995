import { AppContextService } from '../app.context.service';
import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeRu from '@angular/common/locales/ru';
import localeSg from '@angular/common/locales/sg';
import localeTh from '@angular/common/locales/th';
import localeId from '@angular/common/locales/id';
import localeVi from '@angular/common/locales/vi';

@Injectable()
export class StudioLocaleFactory {
  private localeData = this._appContext.get(`common.locale`);

  constructor(private _appContext: AppContextService) {
    // @todo iterate this from list of supported locales
    registerLocaleData(localeFr, `fr-FR`);
    registerLocaleData(localeRu, `ru-RU`);
    registerLocaleData(localeTh, `th-TH`);
    registerLocaleData(localeSg, `sg-SG`);
    registerLocaleData(localeId, `id`);
    registerLocaleData(localeVi);
  }

  getLocaleData() {
    return this.localeData;
  }

  getLocaleId(): string {
    return this.localeData.localeId || `en-US`;
  }
}

export function studioLocale(appContext: AppContextService): any {
  return new StudioLocaleFactory(appContext).getLocaleData();
}

export function studioLocaleId(appContext: AppContextService): any {
  return new StudioLocaleFactory(appContext).getLocaleId();
}
