import { Action } from '@ngrx/store';

export enum PlanActions {
  ADD_AVALIABLE_PLANS = '[PLAN] ADD AVALIABLE PLANS',
  ADD_SELECTED_PLAN = '[PLAN] ADD SELECTED PLAN',
  ADD_APPROVED_POLICY = '[PLAN] ADD APPROVED POLICY',
  ADD_PLAN_TO_SESSION_STORAGE = '[PLAN] ADD PLAN TO SESSION STORAGE',
  ADD_AVALIABLE_ADDONS = '[PLAN] ADD AVALIABLE ADDONS',
  ADD_SELECTED_ADDONS = '[PLAN] ADD SELECTED ADDONS',
  ADD_ADDONS_TO_SESSION_STORAGE = '[PLAN] ADD ADDONS TO SESSION STORAGE',
  ADD_PLAN_STATE = '[PLAN] ADD PLAN STATE',
  ADD_AVALIABLE_BUILDER = '[PLAN] ADD AVALIABLE BUILDER',
  ADD_SELECTED_BUILDER = '[PLAN] ADD SELECTED BUILDER',
  ADD_SELECTED_SME_BUILDER = '[PLAN] ADD SELECTED SME BUILDER',
  ADD_AVALIABLE_COVERAGE = '[PLAN] ADD AVAILABLE COVERAGE',
  ADD_SELECTED_COVERAGE = '[PLAN] ADD SELECTED COVERAGE',
  ADD_TOTAL_PREMIUM = '[PLAN] ADD TOTAL_PREMIUM',
  ADD_AVALIABLE_SME_BUILDER = '[PLAN] ADD AVALIABLE SME BUILDER',
  ADD_AVALIABLE_SME_BUILDER_TO_SESSION_STORAGE = '[PLAN] ADD ADDAVALIABLE SME BUILDER TO SESSION STORAGE',
  ADD_PRODUCT_CONFIG_ID = '[PLAN] ADD PRODUCT CONFIG ID',
  UPDATE_PRODUCT_CONFIG_ID = '[PLAN] UPDATE PRODUCT CONFIG ID',
  ADD__PAYMENT_FREQUENCY = '[PLAN] ADD PAYMENT FREQUENCY',
  ADD_ADDITIONAL_CHARGE = '[PLAN] ADD ADDITIONAL CHARGE',
  ADD_TOTAL_ADDITIONAL_CHARGE = '[PLAN] ADD TOTAL ADDITIONAL CHARGES',
}

export class AddSelectedPlan implements Action {
  readonly type: string = PlanActions.ADD_SELECTED_PLAN;

  constructor(public payload: { [key: string]: string }) {}
}

export class AddAvailablePlans implements Action {
  readonly type: string = PlanActions.ADD_AVALIABLE_PLANS;

  constructor(public payload: { [key: string]: string }) {}
}

export class AddApprovedPolicy implements Action {
  readonly type: string = PlanActions.ADD_APPROVED_POLICY;

  constructor(public payload: { [key: string]: any }) {}
}

export class AddAvailableAddons implements Action {
  readonly type: string = PlanActions.ADD_AVALIABLE_ADDONS;

  constructor(public payload: { [key: string]: [] }) {}
}

export class AddSelectedAddons implements Action {
  readonly type: string = PlanActions.ADD_SELECTED_ADDONS;

  constructor(public payload: { [key: string]: [] }) {}
}

export class AddPlanState implements Action {
  readonly type: string = PlanActions.ADD_PLAN_STATE;

  constructor(public payload: { [key: string]: any }) {}
}

export class AddAvailableBuilder implements Action {
  readonly type: string = PlanActions.ADD_AVALIABLE_BUILDER;

  constructor(public payload: { [key: string]: [] }) {}
}

export class AddAvailableSmeBuiler implements Action {
  readonly type: string = PlanActions.ADD_AVALIABLE_SME_BUILDER;

  constructor(public payload: { [key: string]: [] }) {}
}
export class AddAvailableSmeBuilerToSessionStorage implements Action {
  readonly type: string =
    PlanActions.ADD_AVALIABLE_SME_BUILDER_TO_SESSION_STORAGE;

  constructor(public payload: { [key: string]: [] }) {}
}

export class AddSelectedSmeBuilder implements Action {
  readonly type: string = PlanActions.ADD_SELECTED_SME_BUILDER;

  constructor(public payload: any) {}
}

export class AddSelectedBuilder implements Action {
  readonly type: string = PlanActions.ADD_SELECTED_BUILDER;

  constructor(public payload: { [key: string]: [] }) {}
}

export class AddAvailableCoverage implements Action {
  readonly type: string = PlanActions.ADD_AVALIABLE_COVERAGE;

  constructor(public payload: { [key: string]: [] }) {}
}

export class AddSelectedCoverage implements Action {
  readonly type: string = PlanActions.ADD_SELECTED_COVERAGE;

  constructor(public payload: { [key: string]: [] }) {}
}

export class AddProdctConfigId implements Action {
  readonly type: string = PlanActions.ADD_PRODUCT_CONFIG_ID;

  constructor(public payload: []) {}
}

export class UpdateProdctConfigId implements Action {
  readonly type: string = PlanActions.UPDATE_PRODUCT_CONFIG_ID;

  constructor(public payload: []) {}
}

export class AddTotalPremium implements Action {
  readonly type: string = PlanActions.ADD_TOTAL_PREMIUM;

  constructor(public payload: string) {}
}

export class AddPaymentFrequency implements Action {
  readonly type: string = PlanActions.ADD__PAYMENT_FREQUENCY;

  constructor(public payload: any) {}
}

export class AddAdditionalCharge implements Action {
  readonly type: string = PlanActions.ADD_ADDITIONAL_CHARGE;

  constructor(public payload: string) {}
}

export class AddTotalAdditionalCharge implements Action {
  readonly type: string = PlanActions.ADD_TOTAL_ADDITIONAL_CHARGE;

  constructor(public payload: string) {}
}

export type PlanActionTypes =
  | AddSelectedPlan
  | AddAvailablePlans
  | AddApprovedPolicy
  | AddAvailableAddons
  | AddSelectedAddons
  | AddAvailableBuilder
  | AddSelectedBuilder
  | AddAvailableSmeBuiler
  | AddSelectedSmeBuilder
  | AddAdditionalCharge
  | AddTotalAdditionalCharge;
