import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationButton } from '../navigation-button/navigation-button.model';
import { CardIcon } from './card-icon.model';
import { CardImage } from './card-image.model';
import { Card } from './card.model';

@Component({
  selector: 'csw-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardComponent implements OnInit, Card {
  @Input() header: string;

  @Input() headerTemplate: TemplateRef<any>;

  @Input() underlinedHeader = false;

  @Input() subHeader: string;

  @Input() description: string;

  @Input() template: TemplateRef<any>;

  @Input() icon: CardIcon;

  @Input() name: string;

  @Input() customBackground: boolean;

  @Input() color: 'yellow' | 'green' | undefined;

  @Input() navigationLinks: NavigationButton[];

  @Input() image: CardImage;

  @Input() footer: string;

  @ViewChild('imageEl', { read: ElementRef })
  _imageEl: ElementRef;

  @HostBinding('class') class: string;

  get _colorModificationClass(): string {
    return this.color ? `card--${this.color}` : '';
  }

  get _layoutModificationClass(): string {
    return this.image && this.image.position
      ? `card--layout-image-${this.image.position}`
      : '';
  }

  get _cardNameModificationClass(): string {
    return this.name ? `card--${this.name}` : '';
  }

  get _cardIconModificationClass(): string {
    return this.icon ? `card--icon-${this.icon.position}` : '';
  }

  constructor() {}

  ngOnInit() {
    this.class = `${this.class ? this.class : ''} card ${
      this._colorModificationClass
    } ${this._layoutModificationClass} ${this._cardNameModificationClass} ${
      this._cardIconModificationClass
    }`;
  }
}
