import {
  PrefetchDataActions,
  PrefetchDataActionTypes,
} from '../actions/prefetch-data.action';

export function prefetchDataReducer(
  state = {},
  action: PrefetchDataActionTypes
) {
  switch (action.type) {
    case PrefetchDataActions.ADD_PREFETCH_DATA_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
