import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import { NavigationButtonModule } from '../navigation-button/navigation-button.module';
import { IconModule } from '@crux/components/icon';
import { PipesModule } from '@crux/components/pipes';

@NgModule({
  imports: [CommonModule, NavigationButtonModule, IconModule, PipesModule],
  declarations: [CardComponent],
  exports: [CardComponent],
})
export class CardModule {}
