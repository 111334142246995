import {
  LoadingIndicatorActions,
  LoadingIndicatorActionType,
  LoadingIndicatorHideSuccessAction,
  LoadingIndicatorShowAction,
  LoadingIndicatorShowSuccessAction,
} from '../actions';

export function loadingIndicatorReducer(
  state = [],
  action: LoadingIndicatorActionType
) {
  switch (action.type) {
    case LoadingIndicatorActions.LOADING_INDICATOR_SHOW:
      return state;
    case LoadingIndicatorActions.LOADING_INDICATOR_SHOW_SUCCESS:
      if (
        state.some(
          (li: string) =>
            li === (<LoadingIndicatorShowSuccessAction>action).payload
        )
      ) {
        return state;
      }

      return [...state, (<LoadingIndicatorShowAction>action).payload];
    case LoadingIndicatorActions.LOADING_INDICATOR_HIDE:
      return state;
    case LoadingIndicatorActions.LOADING_INDICATOR_HIDE_SUCCESS:
      return state.filter(
        (key) => key !== (<LoadingIndicatorHideSuccessAction>action).payload
      );
    default:
      return state;
  }
}
