import { Action } from '@ngrx/store';

export enum QuoteDetailsActions {
  ADD_QUOTE_DETAILS = '[QUOTE] ADD QUOTE DETAILS',
  SELECTED_QUOTE_DISCLAIMER = '[QUOTE] SELECTED QUOTE DISCLAIMER',
}

export class AddQuoteDetails implements Action {
  readonly type: string = QuoteDetailsActions.ADD_QUOTE_DETAILS;

  constructor(public payload: { [key: string]: any }) {}
}

export class SelectedQuoteDisclaimer implements Action {
  readonly type: string = QuoteDetailsActions.SELECTED_QUOTE_DISCLAIMER;

  constructor() {}
}

export type QuoteDetailsActionTypes = AddQuoteDetails;
