import { Action } from '@ngrx/store';

export enum PrefetchDataActions {
  ADD_PREFETCH_DATA_SUCCESS = '[PREFETCH DATA] ADD PREFETCH DATA',
}

export class AddPrefetchData implements Action {
  readonly type: string = PrefetchDataActions.ADD_PREFETCH_DATA_SUCCESS;

  constructor(public payload: { [key: string]: any }) {}
}

export type PrefetchDataActionTypes = AddPrefetchData;
