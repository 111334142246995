var STUDIOJS = {
  pages: {
    coi: {
      header: {
        title: {
          content: 'Create a',
        },
        description: {
          content:
            "It only takes a few moments to request a new Certificate of Insurance. Just fill in the fields below and we'll get back to you within one business day.",
        },
      },
      sections: [
        {
          key: 'coi-form-section',
          content: [
            {
              widget: {
                name: 'createCoiForm',
                context: {
                  coiFormControls: [
                    {
                      key: 'horizontalLineTemplate',
                      name: 'horizontalLineTemplate',
                      type: 'template',
                    },
                    {
                      key: 'description',
                      name: 'description',
                      type: 'textarea',
                      label:
                        "Business description (this doesn't represent coverage) (optional)",
                      placeholder: '',
                      required: false,
                      layout: 'row',
                      validationMessages: {
                        required: 'Please enter the description',
                      },
                      options: {
                        type: 'text',
                        appearance: 'legacy',
                        floatLabel: 'auto',
                        hideRequiredMarker: true,
                        maxlength: 400,
                      },
                    },
                    {
                      key: 'horizontalLineTemplate',
                      name: 'horizontalLineTemplate',
                      type: 'template',
                    },
                    {
                      key: 'name',
                      name: 'name',
                      type: 'input',
                      label: "Certificate holder's name",
                      placeholder: '',
                      required: true,
                      cssClass: 'coi-crux-form__name',
                      layout: 'column',
                      validationMessages: {
                        required: "Please enter the certificate holder's name.",
                      },
                      options: {
                        type: 'text',
                        appearance: 'legacy',
                        floatLabel: 'auto',
                        hideRequiredMarker: true,
                        maxlength: 60,
                      },
                    },
                    {
                      key: 'addressLine1',
                      name: 'addressLine1',
                      type: 'addresslookup',
                      label: 'Address line 1',
                      placeholder: '',
                      required: true,
                      cssClass: 'coi-crux-form__address1',
                      layout: 'column',
                      validationMessages: {
                        required: 'Please enter the address',
                      },
                      expressions: {
                        change: (formState) => {
                          if (
                            formState.addressLine1.value &&
                            formState.addressLine1.status.toLowerCase() ===
                              'valid'
                          ) {
                            if (
                              formState.addressLine1.value.City &&
                              formState.addressLine1.value.StateProvince
                            ) {
                              this.coiFormControls
                                .find(
                                  (cityControl) => cityControl.key === 'city'
                                )
                                .formControl.patchValue(
                                  formState.addressLine1.value.City
                                );
                              this.coiFormControls
                                .find(
                                  (stateControl) => stateControl.key === 'state'
                                )
                                .formControl.patchValue(
                                  formState.addressLine1.value.StateProvince
                                );
                            }
                            if (formState.addressLine1.value.PostalCode) {
                              this.coiFormControls
                                .find(
                                  (stateControl) =>
                                    stateControl.key === 'zipcode'
                                )
                                .formControl.patchValue(
                                  formState.addressLine1.value.PostalCode
                                );
                            }
                          }
                        },
                      },

                      options: {
                        dataUrl:
                          'https://commercialservice-sit.chubb.com/api/lookupAddress?Data.AddressLine1=$searchTerm$',
                        addressParam: '$searchTerm$',
                        countryParam: '$country$',
                        country: 'USA',
                        postalCodeParam: '$postalCode$',
                        numberOfRowsParam: '$numRows$',
                        numberOfRows: '10',
                        appearance: 'legacy',
                        floatLabel: 'auto',
                      },
                    },
                    {
                      key: 'addressLine2',
                      name: 'addressLine2',
                      type: 'input',
                      label: 'Address line 2 (optional)',
                      required: false,
                      cssClass: 'coi-crux-form__address2',
                      layout: 'column',
                      options: {
                        floatLabel: 'auto',
                        maxlength: 60,
                        appearance: 'legacy',
                      },
                    },
                    {
                      key: 'city',
                      name: 'city',
                      type: 'input',
                      label: 'City',
                      required: true,
                      cssClass: 'coi-crux-form__city',
                      layout: 'column',
                      validationMessages: {
                        required: 'Please enter a city.',
                      },
                      options: {
                        floatLabel: 'auto',
                        maxlength: 60,
                        appearance: 'legacy',
                      },
                    },
                    {
                      key: 'state',
                      name: 'state',
                      type: 'dropdown',
                      label: 'State',
                      placeholder: 'Please Select',
                      required: true,
                      layout: 'column',
                      cssClass: 'coi-crux-form__state',
                      validationMessages: {
                        required: 'Please enter a state.',
                      },
                      options: {
                        floatLabel: 'auto',
                        maxlength: 60,
                        appearance: 'legacy',
                      },
                    },
                    {
                      key: 'zipcode',
                      name: 'zipcode',
                      type: 'input',
                      label: 'Zip code',
                      required: true,
                      layout: 'column',
                      cssClass: 'coi-crux-form__zipcode',
                      validationMessages: {
                        required: 'Please enter a zip code.',
                        maskValidator: 'Please enter a valid zip code',
                      },
                      options: {
                        floatLabel: 'auto',
                        maxlength: 60,
                        appearance: 'legacy',
                        hideRequiredMarker: true,
                        mask: 'zipCode',
                      },
                    },
                    {
                      key: 'horizontalLineTemplate',
                      name: 'horizontalLineTemplate',
                      type: 'template',
                    },
                    {
                      key: 'emailAddressContentTemplate',
                      name: 'emailAddressContentTemplate',
                      type: 'template',
                    },
                    {
                      key: 'emailAddress',
                      name: 'emailAddress',
                      type: 'input',
                      label: 'Their email address',
                      required: true,
                      layout: 'column',
                      cssClass: 'coi-crux-form__email-address',
                      validationMessages: {
                        required: 'Please enter a valid email address.',
                        email: 'Please enter a valid email address.',
                      },
                      options: {
                        type: 'email',
                        floatLabel: 'auto',
                        maxlength: 60,
                        appearance: 'legacy',
                      },
                    },
                    {
                      key: 'yourMessage',
                      name: 'yourMessage',
                      type: 'textarea',
                      label: 'Your message (optional)',
                      placeholder: '',
                      required: false,
                      layout: 'column',
                      cssClass: 'coi-crux-form__message',
                      options: {
                        floatLabel: 'auto',
                        maxlength: 60,
                        appearance: 'legacy',
                      },
                    },
                    {
                      key: 'phoneNumberContentTemplate',
                      name: 'phoneNumberContentTemplate',
                      type: 'template',
                      options: {
                        content:
                          'We might need to talk to you about your certificate, so please make sure your contact details are correct.',
                      },
                    },
                    {
                      key: 'horizontalLineTemplate',
                      name: 'horizontalLineTemplate',
                      type: 'template',
                    },
                    {
                      key: 'usersPhoneNumber',
                      name: 'usersPhoneNumber',
                      type: 'input',
                      label: 'Phone number',
                      placeholder: '',
                      required: true,
                      layout: 'column',
                      validationMessages: {
                        required: 'Please enter a valid phone number',
                        maskValidator: 'Please enter a valid phone number',
                      },
                      options: {
                        type: 'tel',
                        floatLabel: 'auto',
                        maxlength: 60,
                        appearance: 'legacy',
                        minlength: 10,
                        mask: 'phoneNumber',
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
      ],
    },
  },
};
module.exports = STUDIOJS;
