import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { StudioState } from '../intial-state';
import { DeviceDetailsActions } from '../actions/device-details.action';

@Injectable()
export class DeviceDetailsEffects {
  @Effect()
  navigateToStep$ = this._actions$.pipe(
    ofType(
      DeviceDetailsActions.ADD_SELECTED_PHONE_MAKE_AND_MODEL,
      DeviceDetailsActions.ADD_SELECTED_PHONE_IMEI,
      DeviceDetailsActions.CLEAR_SELECTED_PHONE_DETAILS,
      DeviceDetailsActions.SHOW_MORE_MODELS
    ),
    withLatestFrom(this.store$),
    map(([, storeState]) => {
      sessionStorage.setItem(
        'deviceDetails',
        JSON.stringify(storeState.deviceDetails)
      );
      return {
        type: DeviceDetailsActions.ADD_DEVICE_DETAILS_TO_SESSION_STORAGE,
        payload: false,
      };
    })
  );

  constructor(private _actions$: Actions, private store$: Store<StudioState>) {}
}
