import {
  ScreenerDetailsActionTypes,
  ScreenerDetailsActions,
} from '../actions/screener-details.action';
import { format } from 'date-fns';

export function screenerDetailsReducer(
  state = {},
  action: ScreenerDetailsActionTypes
) {
  switch (action.type) {
    case ScreenerDetailsActions.ADD_SCREENER_DETAILS:
      for (const [key, value] of Object.entries(action.payload)) {
        if (value && (value._isAMomentObject || value instanceof Date)) {
          action.payload[key] = format(value, 'YYYY-MM-DDTHH:mm:ss.SSS');
        }
      }
      return action.payload;
    default:
      return state;
  }
}
