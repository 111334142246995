import { Component, ViewEncapsulation, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LoadingService } from '../shared/loading/loading.service';

@Component({
  selector: 'studio-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageNotFoundComponent {
  constructor(
    private titleService: Title,
    private renderer: Renderer2,
    private _loaderService: LoadingService
  ) {
    this.titleService.setTitle('Page Not Found');
    this.renderer.addClass(document.body, 'chubb-branded');
    this._loaderService.close();
  }
}
