import {
  LanguageConfigActions,
  LanguageConfigActionTypes,
} from '../actions/language.action';

export function languageConfigReducer(
  state = {},
  action: LanguageConfigActionTypes
) {
  switch (action.type) {
    case LanguageConfigActions.LANGUAGE_CONFIG_SUCCESS:
      return { ...state, ...action.payload };
    case LanguageConfigActions.LANGUAGE_CONFIG_FAILURE:
      return { ...state, ...action.payload };
    case LanguageConfigActions.LANGUAGE_CHANGE:
      return { ...state, ...{ selectedLanguage: action.payload } };
    default:
      return state;
  }
}
