import {
  PartnerConfigActions,
  PartnerConfigActionTypes,
} from '../actions/partner.action';
import { Partner } from '../../models/partner';

export function partnerConfigReducer(
  state = {},
  action: PartnerConfigActionTypes
) {
  switch (action.type) {
    case PartnerConfigActions.PARTNER_CONFIG_SUCCESS:
      return { ...state, ...(<Partner>action.payload) };
    case PartnerConfigActions.PARTNER_CONFIG_FAILURE:
      return { ...state, ...(<Partner>action.payload) };
    default:
      return state;
  }
}
