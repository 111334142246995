import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  EventEmitter,
  Output,
  Inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '@crux/services/analytics';
import { STUDIO } from '../../../../../../src/app/const/studio-base-href';

@Component({
  selector: 'csw-navigation-button',
  templateUrl: './navigation-button.component.html',
  styleUrls: ['./navigation-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationButtonComponent implements OnInit {
  @Input() id: string;

  @Input() isNativeButton = true;

  @Input() isPrimaryButton: boolean;

  @Input() isSecondaryButton: boolean;

  @Input() text: string;

  @Input()
  type: 'route' | 'function' | 'url' | 'callback' | 'routeWithParams' = 'route';

  // ToDo: need to be route or function or url + target (self, blank, etc)
  @Input() routeOrFunction: any;

  @Input() queryParamsHandling = false;

  @Input() analyticsEvent: any;

  @Input() color: 'purple' | string;

  @Output() callback = new EventEmitter<boolean>();

  private _baseHref = this._studio.base_href;
  constructor(
    private _router: Router,
    private _analytics: AnalyticsService,
    private _studio: STUDIO
  ) {}

  ngOnInit() {}

  click(e: Event) {
    if (this.analyticsEvent) {
      this._analytics.eventTrack.next(this.analyticsEvent);
    }

    switch (this.type) {
      case 'route':
        this._router.navigate([this._baseHref + this.routeOrFunction], {
          queryParamsHandling: this.queryParamsHandling ? 'merge' : '',
        });
        break;
      case 'function':
        this.routeOrFunction();
        break;
      case 'url':
        (<any>window).location.href = this.routeOrFunction;
        break;
      case 'callback':
        this.callback.emit();
        break;
      case 'routeWithParams':
        this._router.navigateByUrl(this._baseHref + this.routeOrFunction);
        break;
    }
  }

  getColorModification(color: string): string {
    return color ? `navigation-button--${color}` : '';
  }
}
