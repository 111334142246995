import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { StudioState } from '@studio/state/intial-state';
import { Partner } from '@studio/models/partner';
import { Observable } from 'rxjs';
import { AppContextService } from '@studio/app.context.service';
import { LanguageChange } from '../state/actions/language.action';

@Component({
  selector: 'studio-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent implements OnInit {
  partner$: Observable<Partner>;
  footerText: any[];
  footerContactLinks: any[];
  footerSocialLinks: any[];
  footerLinks: any[];
  language: any;

  constructor(
    private _store: Store<StudioState>,
    private _appContext: AppContextService
  ) {
    this.partner$ = this._store.select('partner');
  }

  ngOnInit() {
    const {
      footerText,
      contactLinks,
      socialLinks,
      links,
    } = this._appContext.get('common.footer');
    this.footerText = footerText;
    this.footerContactLinks = contactLinks;
    this.footerSocialLinks = socialLinks;
    this.footerLinks = links;

    const language = this._appContext.get('common.language');
    this.language = language;
  }

  onActivate(e, scrollContainer) {
    scrollContainer.scrollTop = 0;
  }

  languageChanged(selectedLanguage) {
    this._store.dispatch(new LanguageChange(selectedLanguage));
  }
}
